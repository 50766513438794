import { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker } from "antd";
import Layout from "../../components/global/layout";
import environment from "../../environment";
import methodModel from "../../methods/methods";
import dayjs from "dayjs";
import axios from "axios";
import "./style.scss";
const { RangePicker } = DatePicker;
export default function VirtualReport() {
  const user = useSelector((state) => state.user);
  const [VirtualList, setVirtualList] = useState([]);
  const [filters, setfilters] = useState({
    name: "",
    start_date: "",
    end_date: "",
    contractId: "",
    virtualId: "",
    sortBy: "start_date desc",
  });
  const Navigate = useHistory();
  const [VAList, setVAList] = useState([]);
  const [Data, setData] = useState([]);
  const [loading, setloading] = useState(true);
  const searchState = useSelector((state) => state.search);
  const [Count, setCount] = useState(0);

  const GetReports = (p = {}) => {
    const NewFilters = { ...filters, ...p };
    NewFilters.start_date = methodModel.MomentDateConvertor(
      NewFilters.start_date
    );
    NewFilters.end_date = methodModel.MomentDateConvertor(NewFilters.end_date);
    delete NewFilters.name;
    loader(true);
    ApiClient.get(`virtualReport/list`, NewFilters).then((res) => {
      if (res.success) {
        setData(res?.data);
        if (Count == 0 && res?.data[0]?.billings_details) {
          let data = res?.data.map((item) =>
            item?.billings_details?.map((itm) => itm)
          );
          const flattenedData = [].concat(...data);
          let compressedDat = flattenedData;
          compressedDat.map((item, index) => {
            compressedDat[index]["Date"] = new Date(
              `${item?._id.year}/${item?._id.month}/${item?._id.day}`
            );
          });

          const [maxDate, minDate] = methodModel.findMinMaxDates(
            compressedDat,
            "Date"
          );
          setSelectedDateRange([dayjs(minDate), dayjs(maxDate)]);

          setCount(Count + 1);
        }
        setTimeout(() => {
          loader(false);
        }, 400);
        setTimeout(() => {
          setloading(false);
        }, 500);
      }
    });
  };
  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data });
      GetReports({ search: searchState.data });
    }
  }, [searchState]);

  const GetVALIST = () => {
    const filters = { status: "accepted", sortBy: "contractName asc" };
    ApiClient.get(`contracts`, filters).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        const updateddata = [];
        await newdata.map((item, index) => {
          updateddata.push({
            id: item?._id,
            name: item?.contractName || item?.project_details?.name,
          });
        });
        setVAList(updateddata);
      }
    });
  };

  const [SelectedDateRange, setSelectedDateRange] = useState(null);

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setfilters({
      ...filters,
      start_date: dates[0] || "",
      end_date: dates[1] || "",
    });
    GetReports({ start_date: dates[0] || "", end_date: dates[1] || "" });
    // You can perform additional filtering or other actions based on the selected date range
  };

  const GetVirtualList = () => {
    ApiClient.get(
      `users/list?role=${environment.VirtualId}&isDeleted=false&isVerifiedDocument=accepted&sortBy=fullName asc`
    ).then((res) => {
      if (res.success) {
        setVirtualList(res?.data);
      }
    });
  };
  useEffect(() => {
    GetVALIST();
    GetReports();
    GetVirtualList();
  }, []);

  const DownloadVirtualReport = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${
        environment.api
      }exportVirtualReport?start_date=${methodModel.MomentDateConvertor(
        filters?.start_date
      )}&end_date=${methodModel.MomentDateConvertor(
        filters?.end_date
      )}&contractId=${filters?.contractId ? filters?.contractId : ""}&search=${
        filters?.search ?filters?.search:""
      }&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `VirtualReports.xlsx`;
    link.click();
  };

  const DownloadPdf = async (e) => {
    e.preventDefault();
    loader(true);
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${
        environment.api
      }virtual-report/pdf?start_date=${methodModel.MomentDateConvertor(
        filters?.start_date
      )}&end_date=${methodModel.MomentDateConvertor(
        filters?.end_date
      )}&contractId=${filters?.contractId ? filters?.contractId : ""}&search=${
        filters?.search ?filters?.search:""
      }&virtualId=${filters?.virtualId ? filters?.virtualId : ""}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "VirtualReports.pdf";
                a.click();
                loader(false);
              });
          } else {
            loader(false);
          }
        });
    }, 3000);
    loader(false);
  };


  const Sort = (key) => {
    let sorder = "asc";
    if (filters.key === key) {
      sorder = filters.sorder === "asc" ? "desc" : "asc";
    }
    setfilters({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setfilters({ ...filters, sortBy, key, sorder });
    GetReports({ sortBy, key, sorder });
  };

  const searchHandle = (e) => {
    e.preventDefault();
    GetReports({ search: filters?.search });
  };

  const clear = () => {
    setfilters({ ...filters, search: "" });
    GetReports({ search: "" });
  };

  const searchChange = (data) => {
    setfilters({ ...filters, search: data });

    if (!data) {
      GetReports();
    }
  };
  return (
    <>
      <Layout>
        <div className="heightVH">
          {" "}
          <div className="cardit">
            <div className="normalFlex mb-3">
              <h3 className="report_heading mb-0">Virtual Assistant</h3>
              <div className="d-flex">
                <div className="d-flex justify-content-end  set_serchbx  me-3">
                  <form
                    className="headerSearch h-searchOne"
                    onSubmit={(e) => searchHandle(e)}
                  >
                    <input
                      type="text"
                      placeholder="Search..."
                      name="search"
                      value={filters?.search}
                      onChange={(e) => searchChange(e.target.value)}
                      className="form-control"
                    ></input>
                    <i
                      className="fa fa-search"
                      onClick={(e) => searchHandle(e)}
                      aria-hidden="true"
                    ></i>
                    {filters?.search != "" ? (
                      <i
                        className="fa fa-times"
                        onClick={clear}
                        aria-hidden="true"
                      ></i>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
                <button
                  className="btn btn-primary "
                  onClick={(e) => DownloadPdf(e)}
                >
                  Pdf Export
                </button>
                <button
                  className="btn btn-primary ml-2"
                  style={{ float: "right" }}
                  onClick={(e) => DownloadVirtualReport(e)}
                >
                  Export
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-end mt-3 mb-3">
              <div className="d-flex gap-3 flex-wrap align-items-center ">
                <div>
                  <label>Date Filters </label>
                  <RangePicker
                    className="dateRangePicker"
                    placeholder={["Start Date", "End Date"]}
                    format="MM-DD-YYYY"
                    onChange={handleDateChange}
                    value={SelectedDateRange}
                  />
                </div>
                <div className="fullWidth">
                  <label>Contract </label>
                  <div class="dropdown fullWidth ">
                    <button
                      class="btn mb-0 btn-secondary fullWidth dropdown-toggle employer_filt "
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="fa fa-sliders mr-1" aria-hidden="true"></i>
                      <span className="">
                        {" "}
                        {filters.contractId == ""
                          ? "Select Contract"
                          : VAList.find((item) => item.id == filters.contractId)
                              .name}
                      </span>
                    </button>
                    <ul class="dropdown-menu">
                      {VAList.map((item) => (
                        <li>
                          <a
                            class="dropdown-item"
                            onClick={(e) => {
                              setfilters((prev) => ({
                                ...filters,
                                contractId: item?.id,
                              }));
                              GetReports({ contractId: item?.id });
                            }}
                          >
                            {item?.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="fullWidth d-flex gap-2">
                  <div className="fullWidth">
                    {" "}
                    <label>Virtual Assistant </label>
                    <div class="dropdown fullWidth">
                      <button
                        class="btn employer_filt btn-secondary dropdown-toggle fullWidth"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i class="fa fa-sliders mr-1" aria-hidden="true"></i>{" "}
                        {!filters.virtualId
                          ? "Select VA"
                          : VirtualList.find(
                              (item) => item.id == filters.virtualId
                            )?.fullName}
                      </button>
                      <ul class="dropdown-menu">
                        {VirtualList.map((item) => (
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={(e) => {
                                setfilters((prev) => ({
                                  ...filters,
                                  virtualId: item?.id,
                                }));
                                GetReports({ virtualId: item?.id });
                              }}
                            >
                              {item?.fullName}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="clearMargin">
                    <label className="clearOpacity d-block"> clear </label>
                    {filters.contractId ||
                    filters.start_date ||
                    filters.end_date ||
                    filters.virtualId ? (
                      <button
                        className="btn btn-primary mb-0"
                        onClick={() => {
                          setfilters({
                            employerId: "",
                            virtualId: "",
                            end_date: "",
                            start_date: "",
                            contractId: "",
                            name: "",
                          });
                          setSelectedDateRange(null);
                          GetReports({
                            employerId: "",
                            end_date: "",
                            start_date: "",
                            contractId: "",
                            name: "",
                            virtualId: "",
                          });
                        }}
                      >
                        Clear
                      </button>
                    ) : null}
                  </div>
                </div>

              </div>
            </div>

            <div className="">
              {/* <table className='table'>
                        <thead className='table_head'>
                            <tr className='heading_row'>
                                    <th scope="col" className='table_data'>Contract Name</th>
                                <th scope="col" className='table_data'>VA Name</th>
                                <th scope="col" className='table_data'>Date </th>
                                    <th scope="col" className='table_data'>Start Time </th>
                                    <th scope="col" className='table_data'>End Time </th>
                                <th scope="col" className='table_data'>Hours</th>
                                <th scope="col" className='table_data'>Amount</th>

                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((item, index) => (<tr className='data_row'>
                                <td className='text-capitalize'>{item?.projectsDetail?.title}</td>
                                <td className='text-capitalize'>{item?.virtualDetail?.fullName}</td>
                                <td>{moment(item?.billingDetail?.startTime).format('MMM,DD,YYYY ')}</td>
                                <td>{methodModel.(item?.billingDetail?.startTime)}</td>
                                <td>{moment(item?.billingDetail?.endTime).format('')}</td>
                                <td>{parseFloat(item?.totalDurationOfTime||0).toFixed(2)}hr</td>
                                <td>{parseFloat(item?.TotalVirtualHourlyRate||0).toFixed(2)}USD</td>

                            </tr>))}
                        </tbody>
                    </table> */}

              <div className="mt-5">
                <div className="cmc_table2">
                  <div className="row cms cmcRowAdjust firstcms2">
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <label>Contract Name</label>
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <label>Virtual Assistant Name</label>
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <label>Employer Name</label>
                    </div>
                    <div
                      className="col-2 d-flex justify-content-center align-items-center cursor-pointer"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => Sort("start_date")}
                    >
                      <label style={{ cursor: "pointer" }}>Start Date</label>
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <label>Total Hours</label>
                    </div>
                    <div className="col-2 d-flex justify-content-center align-items-center">
                      <label>Total Amount</label>
                    </div>
                  </div>

                  {Data.map((item, index) => (
                    <div className="row cms3 cmcRowAdjust">
                      <div className="col-2 ">
                        <p
                          className={`pms mt-2 ${
                            index % 2 == 0 ? "backit" : "backitagin"
                          } `}
                          style={{ cursor: "pointer" }}
                        >
                          {item?.contractDetail?.createdBy === "admin"
                            ? item?.projectsDetail?.name ||
                              item?.contractDetail?.contractName ||
                              item?.contractDetail?.title
                            : item?.contractDetail?.contractName || "---"}
                        </p>
                      </div>
                      <div className="col-2 ">
                        <p
                          className={`pms mt-2 sixfont ${
                            index % 2 == 0 ? "backit" : "backitagin"
                          } `}
                          onClick={(e) => {
                            Navigate.push(
                              `/details?contractId=${item.contractId}&project=${item?.projectsDetail?._id}&virtual=${item?.virtualId}`
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {item?.virtualDetail?.fullName}{" "}
                        </p>
                      </div>
                      <div className="col-2 ">
                        <p
                          className={`pms mt-2 ${
                            index % 2 == 0 ? "backit" : "backitagin"
                          } `}
                          style={{ cursor: "pointer" }}
                        >
                          {item?.employerDetail?.fullName}{" "}
                        </p>
                      </div>
                      <div className="col-2 ">
                        <p
                          className={`pms mt-2 ${
                            index % 2 == 0 ? "backit" : "backitagin"
                          } `}
                          style={{ cursor: "pointer" }}
                        >
                          {methodModel.convertDate(
                            item?.contractDetail?.startDate
                          )}{" "}
                        </p>
                      </div>
                      <div className="col-2">
                        <b style={{ fontWeight: "300" }} className="text-black">
                          <p className="pms gms">
                            {item?.totalDurationOfTime_hour}:
                            {item?.totalDurationOfTime_min < 10
                              ? "0" + item?.totalDurationOfTime_min
                              : item?.totalDurationOfTime_min}{" "}
                            hrs
                          </p>
                        </b>
                      </div>
                      <div className="col-2">
                        <p className="pms gms">
                          {parseFloat(
                            item?.TotalVirtualHourlyRate || 0
                          ).toFixed(2)}{" "}
                          USD
                        </p>
                      </div>
                      {item?.billings_details &&
                        item?.billings_details[0] &&
                        item?.billings_details?.map((itm) => (
                          <>
                            <div className="col-2 ">
                              <p className="pms ">
                                {" "}
                                {methodModel.convertDate(itm?.startTime)}{" "}
                                {methodModel.ConvertTime(itm?.startTime)}{" "}
                              </p>
                            </div>

                            <div className="col-2 ">
                              <p className="pms ">
                                {" "}
                                {methodModel.convertDate(itm?.endTime)}{" "}
                                {methodModel.ConvertTime(itm?.endTime)}{" "}
                              </p>
                            </div>
                            <div className="col-2 "></div>
                            <div className="col-2 ">
                            </div>
                            <div className="col-2 "></div>
                          </>
                        ))}
                    </div>
                  ))}
                </div>
              </div>
              {loading ? (
                <h3 className="text-center mt-2">Loading...</h3>
              ) : Data.length == 0 ? (
                <div className="text-danger text-center">No Reports here</div>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
