import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router";
import loader from "../../methods/loader";
import { toast } from "react-toastify";
import environment from "../../environment";
import TimezoneSelect from "react-timezone-select";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import dayjs from "dayjs";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;



const Createcontract = () => {
  const history = useHistory();
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const [platformfee, setplatformfee] = useState("");
  const [TimeZone, setTimeZone] = useState(
    user.globallyTimeZone
      ? user.globallyTimeZone
      : user.timeZone
        ? user.timeZone
        : ""
  );
  const [EditRate, setEditRate] = useState(false)
  const [form, setform] = useState({
    // adminId: user?.id,

    hireMore: "false",
    paymentOption: "",
    policy: [],

    timeTable: [
      {
        day: "Sun",
        startTime: "",
        lunchBreak: "",
        endTime: "",
      },
      {
        day: "Mon",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Tue",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Wed",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Thu",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Fri",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Sat",
        startTime: "",
        lunchBreak: "",
        endTime: "",
      },
    ],
  });
  // const [Amount,setAmount]
  // Employer hourly rate calculated automatically
  const [Employerhourluraterate, setEmployerhourluraterate] = useState(0)

  const SetAutoEmployerFee = (value = 0) => {
    let data = value
    const rate = !data ? 0 : methodModel.CalculatePercentAmount(
      data,
      platformfee
    )
    setEmployerhourluraterate(rate)

  }
  const [TotlaAmount, setTotlAmount] = useState()

  // useEffect(() => {
  //   setTotalAmount()
  // }, [form, id])

  const setTotalAmount = (value, hours) => {
    let result = 0;
    let data = value || Employerhourluraterate
    const platformAmount = Number(week);
    const Hours = Number(hours || form?.weeklyLimit);
    const virtualRate = Number(data);
    result = (platformAmount * Hours * virtualRate).toFixed(2);
    return setTotlAmount(parseFloat(Number(result)))
  }

  const [week, setweek] = useState()
  // Get plateform fee for calculating employer hourly rate
  const GetPlateformfee = () => {
    ApiClient.get("setting").then((res) => {
      if (res.success) {
        setplatformfee(res?.data?.platformFee);
        setweek(res?.data?.retainer_hours)
      }
    });
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    let method = "post";
    let url = "contract-admin";
    let value = {
      ...form,
      startDate: moment(form?.startTime || form?.startDate).format("YYYY-MM-DD"),
      title: form?.title,
      totalAmount: TotlaAmount,
      timeZone: TimeZone,
      employerHourlyRate: Employerhourluraterate,
      id: id,
    };
    if (value.id) {
      method = "put";
      url = "contract";
    } else {
      delete value.id;
    }
    delete value?.employerRecuriter
    delete value?.vaRecuriter
    delete value?.vaReferedVA
    loader(true);
    ApiClient.allApi(url, value, method).then((res) => {
      if (res.success) {
        toast.success(res?.message);
        history.goBack();
      }
      loader(false);
    });
  };

  // Get Employer list for dropdown
  const [Employer, setEmployer] = useState([]);

  const GetAllEmployer = () => {
    ApiClient.get(
      `users/list?isVerifiedDocument=accepted&role=${environment?.EmployerId}&sortBy=fullName asc`
    ).then((res) => {
      setEmployer(res?.data);
    });
  };

  // Get Virtual list for dropdown
  const [Virtual, setVirtual] = useState([]);

  const GetAllVirtual = () => {
    ApiClient.get(
      `users/list?isVerifiedDocument=accepted&role=${environment?.VirtualId}&sortBy=fullName asc`
    ).then((res) => {
      setVirtual(res?.data);
    });
  };

  useEffect(() => {
    GetAllEmployer();
    GetAllVirtual();
    GetPlateformfee();
  }, []);

  // Handle onchange Timtable
  const handleChange = (day, field, value) => {
    setform((prevForm) => ({
      ...prevForm,
      timeTable: prevForm.timeTable.map((entry) =>
        entry.day === day ? { ...entry, [field]: value } : entry
      ),
    }));
  };

  // This is function for handle Week start from
  function changeStartingDay(newStartingDay) {
    const startingDayIndex = form?.timeTable?.findIndex(
      (day) => day.day === newStartingDay
    );

    if (startingDayIndex !== -1) {
      const rotatedSchedule = [
        ...form?.timeTable?.slice(startingDayIndex),
        ...form?.timeTable?.slice(0, startingDayIndex),
      ];

      setform({ ...form, timeTable: [...rotatedSchedule] });
    } else {
      console.error(`Invalid starting day: ${newStartingDay}`);
    }
  }

  // This is the timetable schedule
  const StarterSchedule = [
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
  ];
  const EnderSchedule = [
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
    "5:30 PM",
    "6:00 PM",
    "6:30 PM",
    "7:00 PM",
    "7:30 PM",
    "8:00 PM",
    "8:30 PM",
    "9:00 PM",
    "9:30 PM",
    "10:00 PM",
    "10:30 PM",
    "11:00 PM",
    "11:30 PM",
    "12:00 AM",
    "12:30 AM",
    "1:00 AM",
    "1:30 AM",
    "2:00 AM",
    "2:30 AM",
    "3:00 AM",
    "3:30 AM",
    "4:00 AM",
    "4:30 AM",
    "5:00 AM",
    "5:30 AM",
    "6:00 AM",
    "6:30 AM",
    "7:00 AM",
    "7:30 AM",
    "8:00 AM",
    "8:30 AM",
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
  ];
  const LunchSchedule = ["30 Min", "45 Min"];

  useEffect(() => {
    if (id) {
      loader(true);
      ApiClient.get(`contract?id=${id}`).then((res) => {
        if (res.success) {
          const data = res?.data;
          setform({
            contractName: data?.projectId?.name || data?.contractName,
            title: data?.projectId?.name || data?.contractName,
            weeklyLimit: data?.weeklyLimit,
            contractFrom: data?.contractFrom?.id,
            contractTo: data?.contractTo.id,
            hourlyRate: data?.hourlyRate,
            startDate: data?.startDate,
            timeTable: data?.timeTable,
            employerRecuriter: data?.employerRecruiterDetails?.fullName,
            vaRecuriter: data?.virtualRecruiterDetail?.role == environment?.RecruiterId ? data?.virtualRecruiterDetail?.fullName : "",
            vaReferedVA: data?.virtualRecruiterDetail?.role == environment?.VirtualId ? data?.virtualRecruiterDetail?.fullName : "",
          });
          setTotlAmount(data?.totalAmount)
          setEmployerhourluraterate(data?.employerHourlyRate)
          setTimeZone(data?.timeZone);
          loader(false);
        }
      });
    }
  }, [id]);

  const handleEmployers = (value) => {
    const employerData = value ? Employer?.find((item) => item?.id === value) : {}
    const employerRecuriter = Employer?.find((item) => item?.addedBy_details?.role === environment.RecruiterId && item?.id === value)?.addedBy_details?.fullName || ""
    const timeTable = [
      {
        day: "Sun",
        startTime: "",
        lunchBreak: "",
        endTime: "",
      },
      {
        day: "Mon",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Tue",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Wed",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Thu",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Fri",
        startTime: "9:00 AM",
        lunchBreak: "30 Min",
        endTime: "5:00 PM",
      },
      {
        day: "Sat",
        startTime: "",
        lunchBreak: "",
        endTime: "",
      },
    ]
    setTimeZone(value && employerData?.timeZone ? employerData?.timeZone : "")
    setform({ ...form, contractFrom: value, timeTable: employerData?.timeTable?.length > 0 ? employerData?.timeTable : timeTable, employerRecuriter: employerRecuriter })
    setTotalAmount(value ? employerData?.hourlyRate : "")
  }

  // When admin select virtual assistant fetch that va hourlyRate and set in form state and pass hourlyRate in SetAutoEmployerFee function and call setTotalAmount for the calculation
  const handleVirtualAssistant = (value) => {
    const vaHourlyRate = value ? Virtual?.find((item) => item?.id === value)?.hourlyRate : ""
    const vaRecuriter = Virtual?.find((item) => item?.addedBy_details?.role === environment.RecruiterId && item?.id === value)?.addedBy_details?.fullName || ""
    const vaReferedVA = Virtual?.find((item) => item?.addedBy_details?.role === environment.VirtualId && item?.id === value)?.addedBy_details?.fullName || ""
    setform({ ...form, contractTo: value, hourlyRate: vaHourlyRate, vaRecuriter: vaRecuriter, vaReferedVA: vaReferedVA })
    SetAutoEmployerFee(value ? vaHourlyRate : "")
    setTotalAmount(value ? String(vaHourlyRate) : "")
  }

  return (
    <Layout>
      <form onSubmit={(e) => HandleSubmit(e)}>
        <div class="d-flex gap-1 align-items-center mb-3">
          {" "}
          <i
            onClick={(e) => history.goBack()}
            class="fa fa-angle-double-left fontBack text-dark"
            title="Back"
          ></i>{" "}
          <h3 class="hedding">
            {id ? "Edit" : "Create"} Manual Additions
          </h3>
        </div>
        <div className="pprofile1">
          <div className="form-row">
            <div className="col-md-6 mb-3">
              <label>
                Contract Name
                <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="contractName"
                value={form?.title}
                onChange={(e) =>
                  setform({ ...form, title: e.target.value, contractName: e.target.value })
                }
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>
                Weekly Limit
                <span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                name="weeklyLimit"
                value={form?.weeklyLimit}
                maxLength={10}
                onChange={(e) => {
                  setform({ ...form, weeklyLimit: methodModel.isNumber(e) }); setTotalAmount(Employerhourluraterate, methodModel.isNumber(e))
                }
                }
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>
                Employer
                <span className="star">*</span>
              </label>
              <select
                className="form-control text-capitalize"
                name="contractFrom"
                value={form?.contractFrom}
                onChange={(e) => handleEmployers(e.target.value)}
                required
              >
                <option value="">Select Employer</option>
                {Employer?.map((itm) => (
                  <option className="text-capitalize" value={itm?.id}>
                    {itm?.fullName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-6 mb-3">
              <label>
                Virtual Assistant
                <span className="star">*</span>
              </label>
              <select
                className="form-control text-capitalize"
                name="contractTo"
                value={form.contractTo}
                onChange={(e) => handleVirtualAssistant(e.target.value)}
                required
              >
                <option value="">Select Virtual</option>
                {Virtual?.map((itm) => (
                  <option className="text-capitalize" value={itm?.id}>
                    {itm?.fullName}
                  </option>
                ))}
              </select>
            </div>
            {form?.employerRecuriter &&
              <div className="col-md-6 mb-3">
                <label>
                  Employer Recruiter
                  <span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="employerRecuriter"
                  value={form?.employerRecuriter}
                  readOnly
                />
              </div>
            }
            {form?.vaRecuriter &&
              <div className="col-md-6 mb-3">
                <label>
                  Virtual Assistant Recruiter
                  <span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="vaRecuriter"
                  value={form?.vaRecuriter}
                  readOnly
                />
              </div>
            }
            {form?.vaReferedVA &&
              <div className="col-md-6 mb-3">
                <label>
                  Referred VA
                  <span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="vaReferedVA"
                  value={form?.vaReferedVA}
                  readOnly
                />
              </div>
            }
            <div className="col-md-6 mb-3">
              <label>
                Virtual Hourly Rate
                <span className="star">*</span>
              </label>
              <input
                type="text"
                step="0.01"
                className="form-control"
                name="hourlyRate"
                value={form?.hourlyRate}
                onChange={(e) => {
                  if(isNaN(e.target.value)){
                    e.preventDefault()
                  }else{
                  setform({ ...form, hourlyRate: e.target.value }); SetAutoEmployerFee(e.target.value); setTotalAmount(methodModel.CalculatePercentAmount(e.target.value, platformfee))
                  }
                }
                }
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="d-flex">
                Employer Hourly Fee
                <span className="star">*</span>
                <span className="edit_icon  h-auto" >
                  <i class="material-icons edit" title="Edit" onClick={e => setEditRate(true)}>
                    edit
                  </i>
                </span> </label>
              <input
                disabled={!EditRate}
                type="text"
                step="0.01"
                className="form-control"
                name=""
                value={Employerhourluraterate}
                onChange={(e) => {
                  if(isNaN(e.target.value)){
e.preventDefault()
                  }else{
                  setEmployerhourluraterate(e.target.value); setTotalAmount(e.target.value)
                  }
                }
                }
                required
              />
            </div>
            <div className="col-md-6 mb-3">
              <label>
                Total Amount
                <span className="star">*</span>
              </label>
              <input
                type="number"
                step="0.01"
                className="form-control"
                name=""
                value={TotlaAmount}
                disabled
              />
            </div>
            {id ? <div className="col-md-6 mb-3">
              <label>
                Start Date
                <span className="star">*</span>
              </label>
              <input
                disabled={id}
                // min={moment(new Date()).format("YYYY-MM-DD")}
                type={`${id ? "text" : "date"}`}
                className="form-control"
                name="startDate"
                value={moment(form?.startDate).format("MM-DD-YYYY")}
                onChange={(e) =>
                  setform({ ...form, startDate: e.target.value })
                }
                required
              />
            </div> : <div className="col-md-6 mb-3">
              <label>
                Start Date
                <span className="star">*</span>
              </label>
              <DatePicker
                format="MM-DD-YYYY"
                value={form?.startTime ? dayjs(form?.startTime) : ''}
                onChange={(e, DATE) => setform({ ...form, startTime: DATE })}
                placeholder="Select Date and Time"
                className="form-control"
              />

            </div>}
            {/* <div className="col-md-6 mb-3">
                            <label>
                                Employer Hourly Rate
                                <span className="star">*</span>
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                name="employerHourlyRate"
                                value={form?.employerHourlyRate}
                                onChange={e => setform({ ...form, employerHourlyRate: e.target.value })}
                                required
                            />
                        </div> */}
            {/* <div className="col-md-6 mb-3 timeZone">
                            <label>
                                Timezone
                                <span className="star">*</span>
                            </label>
                            <TimezoneSelect value={TimeZone} onChange={(e) => setTimeZone(e) />

                        </div> */}
            <div className="col-md-12">
              <h5 className="mt-4">
                Time Table
                <span className="star">*</span>
              </h5>
            </div>
          </div>
          {/* <div className="contractFirst">
                        <div className="contractSecond">
                            <h5>Days</h5>
                            <p className="timingsP">Start Time</p>
                            <p className="timingsP1">Lunch Time</p>
                            <p className="timingsP2">End Time</p>
                        </div>
                        <div className="contractThird">
                        <div className="">
                        <div className="d-flex contractTabs">
                        {form.timeTable.map((entry,index) => (
                                
                            <div key={entry.day}>
                                <h5 className="ml-3">{entry.day}</h5>
                            
                                <label>
                                    
                                    <select
                                        className=" form-control"
                                        value={entry.startTime}
                                        onChange={(e) => handleChange(entry.day, 'startTime', e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Off">Off</option>
                                        {StarterSchedule?.map((itm) => <option value={itm}>{itm}</option>)}
                                    </select>
                                </label>
                                <br />
                                <label>
                                   
                                    <select
                                        className="form-control"
                                        value={entry.lunchBreak}
                                        onChange={(e) => handleChange(entry.day, 'lunchBreak', e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Off">Off</option>
                                        {LunchSchedule?.map((itm) => <option value={itm}>{itm}</option>)}
                                    </select>
                                </label>
                                <br />
                                <label>

                                    <select
                                        className="form-control"
                                        value={entry.endTime}
                                        onChange={(e) => handleChange(entry.day, 'endTime', e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="Off">Off</option>
                                        {EnderSchedule?.map((itm) => <option value={itm}>{itm}</option>)}
                                    </select>
                                </label>
                           
                            </div>
                        ))}
                        </div>
                       </div>
                        </div>
                    </div> */}

          <div className="row">
            <div className="col-md-6 mb-md-0 mb-3">
              <label>Week Start From </label>
              <select
                value={form?.timeTable?.[0]?.day}
                className="form-control"
                onChange={(e) => changeStartingDay(e.target.value)}
              >
                <option value="Sun">Sunday</option>
                <option value="Mon">Monday</option>
                <option value="Tue">Tuesday</option>
                <option value="Wed">Wednesday</option>
                <option value="Thu">Thursday</option>
                <option value="Fri">Friday</option>
                <option value="Sat">Saturday</option>
              </select>
            </div>

            <div className="col-md-6 mb-3 timeZone">
              <label>
                Timezone
                <span className="star">*</span>
              </label>
              <TimezoneSelect
                value={TimeZone}
                onChange={(e) => setTimeZone(e)}
                required
              />
            </div>
          </div>

          <div className="policies-page mt-4">
            <div className="table-responsive cont_table">
              {" "}
              <table className="table ">
                <thead>
                  <tr>
                    <th>Day</th>
                    {form?.timeTable?.map((entry) => (
                      <th key={entry.day}>{entry.day}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {" "}
                      <span className="pt-2 d-block">Start Time</span>{" "}
                    </td>
                    {form?.timeTable?.map((entry) => (
                      <td key={entry.day}>
                        <select
                          value={entry.startTime}
                          className="form-control widform"
                          onChange={(e) =>
                            handleChange(entry.day, "startTime", e.target.value)
                          }
                        >
                          <option value="Off">Off</option>
                          {StarterSchedule.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <span className="pt-2 d-block">Lunch Break</span>
                    </td>
                    {form?.timeTable?.map((entry) => (
                      <td key={entry?.day}>
                        <select
                          value={entry.lunchBreak}
                          className="form-control widform"
                          onChange={(e) =>
                            handleChange(
                              entry.day,
                              "lunchBreak",
                              e.target.value
                            )
                          }
                        >
                          <option value="Off">Off</option>
                          {LunchSchedule.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                      </td>
                    ))}
                  </tr>
                  <tr>
                    <td>
                      <span className="pt-2 d-block">End Time </span>{" "}
                    </td>
                    {form?.timeTable?.map((entry) => (
                      <td key={entry.day}>
                        <select
                          value={entry.endTime}
                          className="form-control widform"
                          onChange={(e) =>
                            handleChange(entry.day, "endTime", e.target.value)
                          }
                        >
                          <option value="Off">Off</option>
                          {EnderSchedule.map((item) => (
                            <option
                              disable={
                                new Date(entry.endTime) <=
                                new Date(entry.startTime)
                              }
                              value={item}
                            >
                              {item}
                            </option>
                          ))}
                        </select>
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="text-right mt-3">
            {/* <button
              type="button"
              className="btn btn-secondary mr-2"
              onClick={(e) => history.goBack()}
            >
              Back
            </button> */}
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </div>
      </form>
    </Layout>
  );
};
export default Createcontract;