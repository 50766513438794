import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../../components/global/layout';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import './style.scss';
import { useSelector } from 'react-redux';
import { Editor } from "@tinymce/tinymce-react";
import methodModel from '../../../methods/methods';
import environment from '../../../environment';
import parse from 'html-react-parser'
import moment from 'moment';
import { toast } from 'react-toastify';


const CustomerDetail = (p) => {
  const [form, setform] = useState({ resume: "", id: "", resumeDocument: "" })
  const [Comission, setComission] = useState("")
  const [RejectionReason, setRejectionReason] = useState("");
  const [Resume, setResume] = useState("")
  const history = useHistory()
  const user = useSelector(state => state.user)
  const { id, userId } = useParams()
  const [data, setData] = useState()
  const getDetail = (did) => {
    loader(true)
    ApiClient.get(`user/details`, { id: did }).then(res => {
      if (res.success) {
        const newdata = res.data;
        setComission(newdata && newdata.commissionPercentage ? newdata.commissionPercentage : "")
        setData(res.data)
        setform({ resume: newdata.resume ? newdata.resume : "", resumeDocument: newdata.resumeDocument, id: did });
        setResume(newdata.resumeDocument)
      }
      loader(false)
    })
  };

  const back = () => {
    history.goBack()
  }

  const edit = (id) => {
    let url = `/users/edit/${id}`
    if (role) url = `/users/${role}/edit/${id}`
    history.push(url)
  }

  useEffect(() => {
    getDetail(userId ? userId : id)
  }, [id, userId])

  const [EducationData, setEducationData] = useState([]);
  const GetEducation = () => {
    loader(true);
    ApiClient.get(`educations?userId=${id}`).then(res => {
      if (res.success) {
        setEducationData(res.data);
      }
    })
  }
  const [EmployeementData, setEmoloyeementData] = useState([]);
  const GetEmployementData = () => {
    ApiClient.get(`employments?userId=${id}`).then(res => {
      if (res.success) {
        setEmoloyeementData(res.data)
      }
    })
  }
  useEffect(() => {
    GetEducation();
    GetEmployementData();
  }, [])

  const HandleAccpetReject = (e, status, role) => {
    e.preventDefault();
    loader(true);
    const payload = { isVerifiedDocument: status, id: id }
    ApiClient.put(`edit/profile`, payload).then(res => {
      if (res.success) {
        // toast.success(`${role} `)
        getDetail(id);
      }
    })
  }

  const [images, setimages] = useState([])

  const [ProtofolioError, setProtofolioError] = useState(false)

  const [DoumentUploadLoading, setDocumentUploadLoading] = useState(false);
  const [documents, setdocuments] = useState([]);


  const imageResult = (e) => {
    if (e.target.files.length > 0) {
      setDocumentUploadLoading(true);
      const files = e.target.files;
      const newdata = new FormData();
      let newarray = [];
      let imgarrays = [];
      let i = 0;
      let original = [];
      for (let items of files) {
        imgarrays.push(items);
      }
      for (let item of imgarrays) {
        let file = files.item(i);
        ApiClient.multiImageUpload("single/documents?modelName=document", {
          data: file,
        }).then((res) => {
          if (res.success) {
            const path = res?.data?.imagePath;
            setResume(path)
            setform({ ...form, resumeDocument: path })
            setDocumentUploadLoading(false);
          } else {
            setDocumentUploadLoading(false);
            // toast.error({ message: "Some error Occurred ! Try Again!" });
          }
        });

        i++;
      }
      setProtofolioError(false);
      setdocuments(newarray);
      setimages(newarray);
    } else {
      // toast.error({ message: "Please Upload the Documents" });
    }
  };
  //  For Deleting the Document
  const HanldDocumentDelete = (e) => {
    setResume("")
    setform({ ...form, resumeDocument: "" })
  };
  const handleSubmit = (e, type = "") => {
    e.preventDefault();
    let payload = form;
    if (type == "delete") { payload = { resume: "", resumeDocument: "", id: form.id } }
    loader(true);
    ApiClient.put(`edit/profile`, payload).then(res => {
      if (res.success) {
        getDetail(form.id);
        toast.success(`Resume ${type == "delete" ? "Deleted" : "Updated"} Successfully`)
        document.getElementById("closeresume").click();
        loader(false);
      }
    })
  }

  //    This is Method for Rejection 
  const handlerejectionSubmit = (e) => {
    e.preventDefault();
    loader(true);
    const payload = { isVerifiedDocument: "rejected", reason: RejectionReason, id: id }
    ApiClient.put(`edit/profile`, payload).then(res => {
      if (res.success) {
        document.getElementById("closerejection").click()
        toast.success(` ${data?.role && data?.role?.name} Rejected Successfully`)
        getDetail(id);
      }
    })

  }

  function validateYouTubeUrl(url) {
    // var url = $('#youTubeUrl').val();
    if (url != undefined || url != '') {
      var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
      var match = url.match(regExp);
      if (match && match[2].length == 11) {
        return 'https://www.youtube.com/embed/' + match[2]
      }
      else {
        return ""
        // Do anything for not being valid
      }
    }
    else {
      return false
    }
  }



  const HandleCommissionSubmit = (e) => {
    e.preventDefault();
    loader(true);
    const payload = { id: id, commissionPercentage: Comission }
    ApiClient.put(`edit/profile`, payload).then(res => {
      if (res.success) {
        toast.success("Profile updated successfully")
      }
      loader(false)
    })
  }
  const [userHistory, setUserHistory] = useState([])
  const GetUserHistory = () => {
    ApiClient.get(`user-history?userId=${id}`).then((res) => {
      if (res?.success) {
        setUserHistory(res?.data)
      }
    })
  }



  const OpenModal = () => {
    document.getElementById("OpenOldUserDetail").click()
    GetUserHistory()

  }
  return (
    <Layout>
      <div className="text-right mb-3">
        <div className="normalFlex  pr-4 mb-3">
          <h3 className="hedding d-flex gap-1 align-items-center">
            <a to="/users" onClick={back}>
              <i className="fa fa-angle-double-left fontBack"  title='Back' aria-hidden="true"></i>
            </a>
            User Detail
          </h3>
          <div className='d-flex align-items-center mt-md-0 mt-2'>
            {data?.role && data.role.id == "64e83a928599356bddc2fa00" && (data.isVerifiedDocument == "accepted" || data.isVerifiedDocument == "rejected") ? <p className={`text-capitalize btn mr-2 mb-0 btn-${data.isVerifiedDocument == "accepted" ? "success" : "danger"}`}>{data.isVerifiedDocument}</p> : null}
            {/* This is for Employer */}
            {data?.role && data.role.id == environment?.EmployerId && (data.isVerifiedDocument == "accepted" || data.isVerifiedDocument == "rejected") ? <p className={`text-capitalize btn mr-2 mb-0 btn-${data.isVerifiedDocument == "accepted" ? "success" : "danger"}`}>{data.isVerifiedDocument}</p> : null}

            {/* This is for Recruiter */}

            {data?.role && data.role.id == "652393ee64451a10065b76eb" && (data.isVerifiedDocument == "accepted" || data.isVerifiedDocument == "rejected") ? <p className={`text-capitalize btn mr-2 mb-0 btn-${data.isVerifiedDocument == "accepted" ? "success" : "danger"}`}>{data.isVerifiedDocument}</p> : null}
            <button onClick={e => history.push(`/users/edit/${id}`)} className='btn btn-primary d-flex align-items-center gap-1 mb-0'><i class="material-icons edit  text-white  cursor-pointer" title="Edit">edit</i>Edit</button>
            {/* <a to="/users" onClick={back}>  <i className="fa fa-angle-double-left ml-1 backheadarrow" title='Back' aria-hidden="true"></i></a> */}


          </div>
        </div>
      </div>
      <div className="row">
        <div className="sideclass col-md-12">
          <div className="pprofile1">
            <div className="form-row">
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Name</label>
                <div className='profiledetailscls'>{data && data.fullName}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Mobile No</label>
                <div className='profiledetailscls'>{data && data.dialCode}{" "} {data && data.mobileNo}</div>
              </div>

              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Country</label>
                <div className='profiledetailscls'>{data?.country}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">State</label>
                <div className='profiledetailscls'>{data?.state}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">City</label>
                <div className='profiledetailscls'>{data?.city}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Address</label>
                <div className='profiledetailscls'>{data?.address}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Email</label>
                <div className='profiledetailscls'>{data && data.email}</div>
              </div>
              <div className="col-md-6 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Role</label>
                <div className='profiledetailscls'>{data?.role?.name}</div>
              </div>
              {data && data.role && data.role.id == environment?.RecruiterId || data && data.role && data.role.id == environment?.VirtualId ?
                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls profileheddingclsfont">Commission</label>
                  <div className='profiledetailscls'>{data?.commissionPercentage}%</div>
                </div> : null}

              {/* {data?.isVerifiedDocument === "accepted" ? <div className="col-md-12 mb-3">
                <button className='btn btn-primary' onClick={e => OpenModal()} style={{ float: "right" }}>Log File</button>
              </div> : null
              } */}
              <div className="col-md-12 mb-3">
                <label className="profileheddingcls profileheddingclsfont">Image</label>
                <div>
                  <div className="imagethumbWrapper">
                    <img src={methodModel.userImg(data && data.image)} className="uploadimage" />
                  </div>
                </div>
              </div>
              {/*  Here we need to show the Employeer data */}
              {data?.role && data?.role?.id == "64e5cdfc5bb9a041a39046d5" ? <>
                <div className="col-md-12">
                  <hr />
                </div>
                <div className='col-md-12 mb-3'>
                  <label className="profileheddingcls profileheddingclsfont">Company Documents</label>
                  {/* <hr /> */}
                  <div className='profiledetailscls'>{data?.document && data.document.map((item, index) => (
                    <img style={{ cursor: "pointer" }} src='https://cdn1.iconfinder.com/data/icons/human-resources-33/100/hr-06-512.png' onClick={e => window.open(`${environment.api}images/users/${item}`)} width={40} height={40} />
                  ))}</div>
                </div>

                <div className="col-md-12 mb-3">
                  <label className="profileheddingcls profileheddingclsfont">Company Details</label>
                  {/* <hr /> */}
                  <div className=''>
                    <div className="imagethumbWrapper">
                      <img className='uploadimage' src={`${!data?.companyImage ? "/assets/img/noimage.jpg" : environment.api + "images/users/" + data?.companyImage}`} />
                    </div>
                    <div className='row mt-3'>
                      <div className='col-md-6 mb-3 '>
                        <label className="profileheddingcls profileheddingclsfont">Name </label>
                        {data.companyname == "" ? null : <div className='profiledetailscls'>{data?.companyName}</div>}</div>
                      <div className='col-md-6 mb-3'>
                        <label className="profileheddingcls profileheddingclsfont">Website</label>
                        {data?.companyWebsite == "" ? null : <div className='profiledetailscls'>{data.companyWebsite}</div>}</div>

                      <div className='col-md-6 mb-3'>
                        <label className="profileheddingcls profileheddingclsfont">StartDate</label>
                        {data.companyStartDate == "" ? null : <div className='profiledetailscls'>{moment(data?.companyStartDate).format("DD MMM YYYY")}</div>}</div>                                        <div className='col-md-6'>

                        <div className='col-md-6'>
                          <label className="profileheddingcls profileheddingclsfont">Owner</label>
                          {data?.contactName == "" ? null : <div className='profiledetailscls'>{data?.contactName}</div>}</div>

                      </div>
                      <div className='col-md-6 mb-3'>
                        <label className="profileheddingcls profileheddingclsfont">Email</label>
                        {data.contactEmail == "" ? null : <div className='profiledetailscls'>{data?.contactEmail}</div>}</div>
                      <div className='col-md-6 mb-3'>
                        <label className="profileheddingcls profileheddingclsfont">MobileNumber</label>
                        {data.companyMobileNo == "" ? null : <div className='profiledetailscls'>{data?.companyMobileNo}</div>}</div>
                      <div className='col-md-12 mb-3'>
                        <label className="profileheddingcls profileheddingclsfont">Description</label>
                        {data.companyDescription == "" ? null : <div className='profiledetailscls'>{data.companyDescription}</div>}</div>
                    </div>
                  </div>
                </div>

              </> : null}
              {/*  Here We need to show the all things thats the Virtual Assitance Submit in Form  */}
              {data?.role && data.role.id == "64e83a928599356bddc2fa00" ? <>



                <div className="col-md-6 mb-3">
                  <label className="profileheddingcls profileheddingclsfont">HourlyRate</label>
                  <div className='profiledetailscls'>{data?.hourlyRate}{" "}USD</div>
                </div>
                {data.portfolioUrl != "" ? (
                  <div className='col-md-12'>
                    <div className="col-md-6 mb-3 px-0">
                      <label className="profileheddingcls profileheddingclsfont">PortfolioUrl</label>
                      <div className='profiledetailscls'>{data?.portfolioUrl}</div>
                    </div>
                    {data.portfolioImage && data.portfolioImage.length > 0 ? <div className="col-md-6 mb-3 px-0">
                      <label className="profileheddingcls profileheddingclsfont">Portfolio Document</label>
                      <div className='profiledetailscls'>{data?.portfolioImage && data.portfolioImage.map((item, index) => (
                        <img style={{ cursor: "pointer" }} src='https://cdn1.iconfinder.com/data/icons/human-resources-33/100/hr-06-512.png' onClick={e => window.open(`${environment.api}images/users/${item}`)} width={40} height={40} />
                      ))}</div>
                    </div> : null}
                  </div>
                ) : (
                  ""
                )}


                {data.skillType?.length != 0 ? (
                  <div className="col-md-6 mb-3">
                    <label className="profileheddingcls profileheddingclsfont">Skills</label>
                    <div className='profiledetailscls'>
                    <div className='d-flex flex-wrap skills-set'>
                    {data.skillType && data.skillType.map((itm, index) => (<span className='gray_badges'>{itm.name}</span>))}
                  </div>
                    </div>
                  </div>

                ) : (
                  ""
                )}
                <div className="col-md-12 mb-3">
                  <label className="profileheddingcls profileheddingclsfont">Education</label>
                  <hr />
                  <div className=''>
                    {EducationData.map((item, index) => (
                      <div className='row'>
                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">Degree</label>
                          <div className='profiledetailscls'>{item.degree}</div></div>
                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">School</label>
                          <div className='profiledetailscls'>{item.school}</div></div>

                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">StudyArea</label>
                          <div className='profiledetailscls'>{item.studyArea}</div></div>


                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">StartDate</label>
                          <div className='profiledetailscls'>{item.startDate}</div>
                        </div>

                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">EndDate</label>
                          <div className='profiledetailscls'>{item.endDate}</div></div>
                        <div className='col-md-12 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">Description</label>
                          <div className='profiledetailscls'>{item.description}</div></div>
                      </div>
                    ))}
                  </div>
                </div>


                {/*  For Employeements */}
                <div className="col-md-12 mb-3">
                  <label className="profileheddingcls profileheddingclsfont">Employment </label><hr />
                  <div className=''>
                    {EmployeementData.map((item, index) => (
                      <div className='row'>
                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">Company</label>
                          <div className='profiledetailscls'>{item.company}</div></div>
                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">Title</label>
                          <div className='profiledetailscls'>{item.title}</div></div>


                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">StartDate</label>
                          <div className='profiledetailscls'>{item.startDate}</div></div>


                        <div className='col-md-6 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">EndDate</label>
                          <div className='profiledetailscls'>{item.currentlyWorking ? "Working Here" : item.endDate}</div></div>
                        <div className='col-md-12 mb-3'>
                          <label className="profileheddingcls profileheddingclsfont">Description</label>
                          <div className='profiledetailscls'>{item.description}</div></div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-md-12'>
                  <label className="profileheddingcls profileheddingclsfont">Documents</label><hr />
                  <div className='profiledetailscls'>{data?.document && data.document.map((item, index) => (
                    <img style={{ cursor: "pointer" }} src='https://cdn1.iconfinder.com/data/icons/human-resources-33/100/hr-06-512.png' onClick={e => window.open(`${environment.api}images/users/${item}`)} width={40} height={40} />
                  ))}</div>
                </div>

                <div className='col-md-12 mt-3'>
                  <div className="d-flex align-items-center justify-content-between">
                    <label className="profileheddingcls profileheddingclsfont">Resume</label>
                    <div>
                      {data.resume != "" || data.resumeDocument != "" ? <>
                        <i title='Delete' onClick={e => { handleSubmit(e, "delete") }} className='fa fa-trash text-danger mr-3'></i>
                        <i title='Edit' data-toggle="modal" data-target="#resumeedit" className='fa fa-pen text-info'></i>
                      </> : null}</div>
                  </div>
                  <hr />
                  <label className='profileheddingcls profileheddingclsfont' >Resume</label>
                  <div className='profiledetailscls'>{parse(`${data.resume}`)}</div>
                  <div className='profiledetailscls'>{data?.resumeDocument != "" ?
                    <img style={{ cursor: "pointer" }} src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSVlvvKw31TIIrVV3C6ZVXErB8sDduDYcyAFg&usqp=CAU' onClick={e => window.open(`${environment.api}images/document/${data.resumeDocument}`)} width={50} /> : null
                  }</div>
                </div>

                <div className='col-md-12 mt-3'>
                  <label className="profileheddingcls profileheddingclsfont">Video Introduction </label><hr />
                  {data.introLink != "" ? <div>
                    <label className='profileheddingcls profileheddingclsfont' >Videourl</label>
                    <div className='profiledetailscls'>
                      <iframe width="300" height="230" src={`${validateYouTubeUrl(data.introLink)}`} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>
                  </div> : null}

                  <div className='mt-3'>
                    <label className='profileheddingcls profileheddingclsfont' >Video</label>
                    <div className=''>{data.introVideo == "" ? null : <video width={400} height={150} controls src={environment.api + "images/videos/" + data.introVideo} />}</div>
                  </div>

                  {data?.role && data.role.id == environment.VirtualId && data?.isVerifiedDocument == "rejected" ? <div className='mt-3'>
                    <label className="profileheddingcls profileheddingclsfont">Reajected Reason</label>
                    {<div className='profiledetailscls text-danger'>{data?.reason}</div>}</div> : null}
                </div>




                {data.isVerifiedDocument != "accepted" ? <div className="col-md-6 mb-3 mt-4">
                  <button type="button" className="btn btn-primary " onClick={e => HandleAccpetReject(e, "accepted")} >Accept</button>
                  <button type="button" className="btn btn-secondary ml-2" data-toggle="modal" data-target="#openrejectmodal">Reject</button>
                </div> : null}
              </> : null}
              {/* This is for Employer */}
              {data?.role && data.role.id == environment?.EmployerId && data.isVerifiedDocument != "accepted" ? <div className="col-md-6 mb-3 mt-4">
                <button type="button" className="btn btn-primary " onClick={e => HandleAccpetReject(e, "accepted", "Employer")} >Accept</button>
                <button type="button" className="btn btn-secondary ml-2" data-toggle="modal" data-target="#openrejectmodal">Reject</button>
              </div> : null}
              {/* This is for Recruiter */}
              {data?.role && data.role.id == "652393ee64451a10065b76eb" && data.isVerifiedDocument != "accepted" ? <div className="col-md-6 mb-3 mt-4">
                <button type="button" className="btn btn-primary " onClick={e => HandleAccpetReject(e, "accepted", "Recruiter")} >Accept</button>
                <button type="button" className="btn btn-secondary ml-2" data-toggle="modal" data-target="#openrejectmodal">Reject</button>
              </div> : null}
            </div>
          </div>
        </div>
      </div>

      {/*  For Resume Modal  */}
      <button type="button" class="btn btn-primary d-none" data-toggle="modal" data-target="#resumeedit">
        Launch demo modal
      </button>
      <div class="modal fade" id="resumeedit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" id='closeresume' data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="pprofile1">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="ViewUser">Resume</h3>
                </div>
                <form className="form-row" onSubmit={handleSubmit}>
                  <div className="main_profile_page">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="">
                          <div className="row">
                            {/* new */}

                            <>
                              {" "}
                              <div className="col-md-12 mt-3">
                              </div>
                              <br />
                              <div className="col-md-12 mt-3">
                                <div className="form-group">
                                  <label for="exampleFormControlTextarea1">
                                    Resume
                                  </label>
                                  <Editor
                                    apiKey={environment.tinyKey}
                                    value={form.resume}
                                    onEditorChange={(newValue, editor) => {
                                      setform({
                                        ...form,
                                        resume: newValue,
                                      })
                                    }}
                                    init={{
                                      selector: 'textarea',
                                      plugins: 'table code colorpicker textcolor',
                                      toolbar: 'table code bold italic alignleft aligncenter alignright alignjustify | outdent indent ',
                                      toolbar_mode: 'floating',

                                    }}
                                  />

                                </div>
                              </div>
                              <div className="col-md-12 mt-3">
                                <label>Resume Document</label>
                                <div className={`profile_btn_portfolio d-${Resume == "" ? "" : "none"} `}>
                                  <label className="add_portfolio edit ml-3">
                                    <input
                                      id="bannerImage"
                                      type="file"
                                      className="d-none"
                                      // accept="image/*"
                                      onChange={(e) => imageResult(e)}
                                    />
                                    <span className="add_portfolio">
                                      <i class="material-icons add_port">add</i>
                                    </span>
                                  </label>
                                </div>
                              </div>
                              <div>
                                <br />
                                <div class="imagesRow mt-3 mr-5 d-flex flex-wrap">
                                  {DoumentUploadLoading == true ? (
                                    <div className="text-success">
                                      Uploading...{" "}
                                      <i className="fa fa-spinner fa-spin"></i>
                                    </div>
                                  ) : (Resume != "" ?
                                    <div>
                                      <p className="text-capitalize">
                                        <img
                                          style={{ cursor: "pointer" }}
                                          width={40}
                                          className="document_image"
                                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5kekkj6iYr_ZSKfB7GRWEJ4q-7_2sruMm5Hbc2haxduVzPhmc1kS-7OKKJDI1FjTEVAg&usqp=CAU"
                                          onClick={(e) =>
                                            window.open(
                                              `${environment.api}images/document/${Resume}`
                                            )
                                          }
                                        />
                                        <i
                                          className="fa fa-trash text-danger shadow-danger delet_icons"
                                          style={{ cursor: "pointer" }}
                                          onClick={(e) =>
                                            HanldDocumentDelete(e)
                                          }
                                        ></i>
                                      </p>
                                    </div> : null
                                  )}
                                </div>
                              </div>
                              {ProtofolioError ? (
                                <div className="text-danger text-center mt-3 ml-5">
                                  Please enter Resume or Upload Document{" "}
                                </div>
                              ) : null}
                            </>

                            <div className="col-md-12 d-flex justify-content-end mt-3">
                              <button data-dismiss="modal" onClick={e => getDetail(id)} className="btn btn-primary reset">
                                Discards
                              </button>
                              <button
                                type="submit"
                                className="btn btn-primary edit ml-3"
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="modal-footer">
            </div>
          </div>
        </div>
      </div>

      {/*  Modal for Reject  */}

      <button type="button" class="btn btn-primary d-none" data-toggle="modal" data-target="#openrejectmodal">
        Launch demo modal
      </button>
      <div class="modal fade" id="openrejectmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header  pb-3">
              <h5 class="modal-title" id="exampleModalLabel">Reject</h5>
              <button type="button" id='closerejection' class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={e => handlerejectionSubmit(e)}>
              <div class="modal-body my-4 pt-0">

                <div>
                  <label>Reason for Rejection</label>
                </div>
                <textarea
                  className='form-control reasionborder'
                  rows={6}
                  required value={RejectionReason} onChange={e => setRejectionReason(e.target.value)}
                />
                {/* <input required value={RejectionReason} onChange={e=>setRejectionReason(e.target.value)} className='form-control reasionborder' />  */}


              </div>
              <div class="modal-footer  mb-1 d-flex justify-content-between">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary ">Save changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button type="button" class="btn btn-primary d-none" id='OpenOldUserDetail' data-bs-toggle="modal" data-bs-target="#exampleModal">
        Launch demo modal
      </button>

      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"> Old User Detail</h5>
            </div>
            <div class="modal-body">
              <div className='table-responsive'>
                <table class="table ">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Mobile No.</th>
                      <th scope="col">Address</th>
                      <th scope="col">Company Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userHistory?.map((itm, i) =>
                      <>
                        {itm?.oldUserData.map((item) =>
                          <tr>
                            <td >{item?.fullName || "---"}</td>
                            <td>{item?.email || "---"}</td>
                            <td>{item?.dialCode}{" "}{item?.mobileNo || "---"}</td>
                            <td>{item?.address || "---"}</td>
                            <td>{item?.companyName || "---"}</td>
                          </tr>)
                        }
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </Layout>
  );
};

export default CustomerDetail;
