import React, { useEffect, useState } from "react";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import Pagination from "react-pagination-js";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Virtualpayment = () => {
  const [VirtualList, setVirtualList] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    count: 20,
    search: "",
    contractId: "",
    virtualId: "",
  });
  const [total, settotal] = useState(0);
  const searchState = useSelector((state) => state.search);
  const user = useSelector((state) => state.user);
  const navigate = useHistory();
  const [loading, setloading] = useState(true);

  // For searchbar
  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filter, search: searchState.data });
      GetVirtualList({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  // For Handle pagination
  const HandlepageChange = (number) => {
    setFilter({ ...filter, page: number });
    GetVirtualList({ page: number });
  };

  const GetVirtualList = (p = {}) => {
    const filt = {
      ...filter,
      ...p,
      employerReview: "approved",
      billingStatus: "submitted",
    };
    loader(true);
    ApiClient.get(`twoWeek/billings`, filt).then((res) => {
      if (res.success) {
        loader(false);
        setVirtualList(res?.data);
        settotal(res.total);
      }
      setloading(false);
    });
  };

  const [VAList, setVAList] = useState([]);
  const GetVALIST = () => {
    ApiClient.get(`contracts?search=&status=accepted&addedBy=`).then(
      async (res) => {
        if (res.success) {
          const newdata = res.data;
          const updateddata = [];
          await newdata.map((item, index) => {
            updateddata.push({
              id: item?._id,
              name: item?.project_details?.title || item?.project_details?.name,
            });
          });
          setVAList(updateddata);
        }
      }
    );
  };

  useEffect(() => {
    GetVALIST();
    GetVirtualList();
  }, []);

  // const [Virtual, setVirtuat] = useState([]);
  // const GetVaList = () => {
  //     ApiClient.get(
  //         `users/list?role=${environment.VirtualId}&isDeleted=false&isVerifiedDocument=accepted`
  //     ).then((res) => {
  //         if (res.success) {
  //             setVirtuat(res?.data);
  //         }
  //     });
  // };
  // useEffect(() => {
  //     GetVaList();
  // }, []);

  const Getcalculculateminute = (res) => {
    let hours = parseInt(res?.totalDurationOfTime_hour1);
    let minutes = parseInt(res?.totalDurationOfTime_min1);
    let remainder = minutes / 60;
    let quotient = minutes % 60;
    hours += parseInt(remainder);
    minutes = quotient;
    return `${hours || "00"} : ${
      minutes < 10 ? "0" + minutes : minutes || "00"
    }`;
  };

  const CheckSameNames = (index, name) => {
    let foundMatch = false;
    VirtualList.forEach((item, i) => {
      if (
        i !== index &&
        item?.billingData[0]?.addedByDetail?.fullName === name
      ) {
        foundMatch = true;
      }
    });
    return foundMatch;
  };

  return (
    <>
      <Layout>
        <div className="heightVH">
          {" "}
          <div className="">
            <div className="faq-title  pb-2 d-flex justify-content-between align-items-center">
              <h2 className="hedding">VA Payments</h2>
              <div className="d-flex align-items-center">
              <div className="fullWidth widthpayments">
                    <div class="dropdown ">
                      <button
                        class="btn btn-secondary dropdown-toggle employer_filt d-flex align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >  
                        <i class="fa fa-sliders mr-2" aria-hidden="true"></i>
                        <span className="textep">
                          {" "}
                          {filter.contractId == ""
                            ? "Select Contract"
                            : VAList.find(
                                (item) => item.id == filter.contractId
                              ).name}
                        </span>
                      </button>
                      <ul class="dropdown-menu new-drop">
                        {VAList.map((item) => (
                          <li>
                            <a
                              class="dropdown-item"
                              onClick={(e) => {
                                setFilter((prev) => ({
                                  ...filter,
                                  contractId: item?.id,
                                }));
                                GetVirtualList({ contractId: item?.id });
                              }}
                            >
                              {item?.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                {/* <div className='ml-3'>
                                <label>Virtual Assistant  </label>
                                <div class="dropdown">
                                    <button class="btn employer_filt w-100 btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <i class="fa fa-sliders mr-1" aria-hidden="true"></i> {!filter.virtualId ? "Select VA" : Virtual.find(item => item.id == filter.virtualId)?.fullName}

                                    </button>
                                    <ul class="dropdown-menu">
                                        {Virtual.map((item) => (
                                            <li>
                                                <a
                                                    class="dropdown-item"
                                                    onClick={(e) => {
                                                        setFilter((prev) => ({
                                                            ...filter,
                                                            virtualId: item?.id,
                                                        }));
                                                        GetVirtualList({ virtualId: item?.id });
                                                    }}
                                                >
                                                    {item?.fullName}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div> */}
                <div className="ml-3">
                  {filter?.contractId || filter?.virtualId ? (
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        setFilter({ contractId: "" });
                        GetVirtualList({ contractId: "" });
                      }}
                    >
                      Clear
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className=" table table-responsive table_section virtualNamesTable mt-3 ">
            <table class="table">
              <thead className="table_head">
                <tr className="heading_row">
                  <th scope="col" className="table_data">
                    Contract Name
                  </th>
                  <th scope="col" className="table_data">
                    Virtual Name
                  </th>
                  <th scope="col" className="table_data">
                    Virtual Rate
                  </th>
                  <th scope="col" className="table_data">
                    Time Period
                  </th>
                  <th scope="col" className="table_data">
                    Total Hours
                  </th>
                  <th scope="col" className="table_data">
                    Total Amount
                  </th>
                  <th scope="col" className="table_data">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {VirtualList.map((item, index) => (
                  <tr className="data_row">
                    <td className="table_dats ">
                      {item?.billingData[0]?.projectDetail?.title}
                    </td>

                    <td>
                      {" "}
                      <p
                        className={`${
                          CheckSameNames(
                            index,
                            item?.billingData[0]?.addedByDetail?.fullName
                          )
                            ? "virtualNames"
                            : "virtualNamesTwo"
                        } `}
                      >
                        {" "}
                        {item?.billingData[0]?.addedByDetail?.fullName || "---"}
                      </p>{" "}
                    </td>

                    <td className="table_dats">
                      {item?.billingData[0]?.addedByDetail?.hourlyRate < 10
                        ? "0" + item?.billingData[0]?.addedByDetail?.hourlyRate
                        : item?.billingData[0]?.addedByDetail?.hourlyRate ||
                          ""}{" "}
                      USD
                    </td>

                    <td className="table_dats">
                      {moment(item?.startDate).format("DD MMM")} -{" "}
                      {moment(item?.endDate).format("DD, YYYY")}
                    </td>

                    <td className="table_dats">
                      {" "}
                      {Getcalculculateminute(item)} hrs
                    </td>

                    <td className="table_dats">
                      {" "}
                      {parseFloat(item?.totalBillingAmount).toFixed(2)} USD
                    </td>
                    <td className="table_dats">
                      {item?.isPaid === true ? (
                        <button
                          title="Paid"
                          disabled
                          className="btn btn-primary moneyButtons"
                        >
                          Paid
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary moneyButtons"
                          onClick={(e) =>
                            navigate.push(
                              `/payment/quote?contractId=${item?._id?.contractId}&start_Date=${item?.startDate}&end_Date=${item?.endDate}&name=${item?.billingData[0]?.addedByDetail?.fullName}&totalDurationOfTime_hour1=${item?.totalDurationOfTime_hour1}&totalDurationOfTime_min1=${item?.totalDurationOfTime_min1}&totalBillingAmount=${item?.totalBillingAmount}`
                            )
                          }
                        >
                          Pay Now
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-3">
            {" "}
            {/* {VirtualList.length === 0 ? (
              <h1 className="text-center nodatath">No Data</h1>
            ) : (
              " "
            )} */}
            {loading ? (
              <h3 className="text-center mt-2">Loading...</h3>
            ) : VirtualList.length == 0 ? (
              <div className="text-danger text-center">No Data</div>
            ) : null}
          </div>
          {total > filter.count ? (
            <div style={{ float: "right" }} className="paginationWrapper">
              <Pagination
                currentPage={filter.page}
                totalSize={total}
                sizePerPage={filter.count}
                changeCurrentPage={HandlepageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </Layout>
    </>
  );
};
export default Virtualpayment;
