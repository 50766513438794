
const Html = ({ ListItemLink, tabclass, urlAllow, route }) => {
  return (
    // <ul className="nav flex-column" component="siderbar">
    <div className="sidebar_scrolling">

      <ul className="nav flex-column" component="siderbar">
        {urlAllow("readDashboard") ? (
          <ListItemLink to="/dashboard">
            <i class="material-icons  mr-2" title="Dashboard">
              dashboard
            </i>
            <span class="side_head">Dashboard</span>
          </ListItemLink>
        ) : null}

        <li className="nav-item">
          {urlAllow("readRole") || urlAllow("readAdmins") ? (
            <>
              <a
                class={`col_side ${tabclass("user") ? "" : "collapsed"}`}
                data-bs-toggle="collapse"
                href="#collapseExample"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <div className="sidees">
                  <i class="material-icons mr-2" title="User Management">
                    manage_accounts
                  </i>
                  <span>User Management</span>
                </div>
                <div>
                  <span className="side_icon">
                    <i class="material-icons">keyboard_arrow_right</i>
                  </span>
                </div>
              </a>
            </>
          ) : (
            <></>
          )}

          <div
            class={`collapse ${tabclass("user") ? "show" : ""}`}
            id="collapseExample"
          >
            <div class="card card-body sides">
              {urlAllow("readRole") ? (
                <>
                  <ListItemLink to="/roles" onClick={e => localStorage.removeItem("rolesstatus")}>
                    {/* <i class="material-icons sidenv mr-2" title="Roles">supervised_user_circle</i> */}
                    <div className="sidees">
                      <i class="material-icons mr-2" title="Roles">
                        checklist
                      </i>
                      <span>Roles</span>
                    </div>


                  </ListItemLink>
                </>
              ) : (
                <></>
              )}
              {urlAllow("readAdmins") ? (
                <>
                  <ListItemLink to="/users" onClick={e => { localStorage.removeItem("statususer"); localStorage.removeItem("tabfilter") }}>
                    <div className="sidees">
                      <i class="material-icons mr-2" title="Users">
                        group
                      </i>
                      <span>Users</span>
                    </div>

                  </ListItemLink>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </li>


        <li className="nav-item">
          {urlAllow("readRole") || urlAllow("readAdmins") ? (
            <>
              <a
                class={`col_side ${tabclass("user") ? "" : "collapsed"}`}
                data-bs-toggle="collapse"
                href="#collapseExample3"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample3"
              >
                <div className="sidees">
                  <i class="material-icons mr-2" title="Reports">
                    query_stats
                  </i>
                  <span>Reports</span>
                </div>
                <div>
                  <span className="side_icon">
                    <i class="material-icons">keyboard_arrow_right</i>
                  </span>
                </div>
              </a>
            </>
          ) : (
            <></>
          )}

          <div
            class={`collapse ${tabclass("reports") ? "show" : ""}`} id="collapseExample3" >
            <div class="card card-body sides">
              <>
                <ListItemLink to="/employer">
                  <div className="sidees">
                    <i class="material-icons mr-2" title="Employer">
                      remember_me
                    </i>
                    <span>Employer </span>
                  </div>


                </ListItemLink>
              </>
              <>
                <ListItemLink to="/virtual">
                  <div className="sidees">
                    <i class="material-icons mr-2" title="Virtual Assistant">
                      support_agent
                    </i>
                    <span>Virtual Assistant</span>
                  </div>

                </ListItemLink>
              </>
              <>
                <ListItemLink to="/recruiter">
                  <i class="fa fa-tasks sideicon mr-2" aria-hidden="true"></i>{" "}
                  <span>Recruiter</span>
                </ListItemLink>
              </>
              <>
                <ListItemLink to="/report">
                  <i class="fa fa-line-chart sideicon mr-2" aria-hidden="true"></i>{" "}
                  <span>Net Profit </span>
                </ListItemLink>
              </>
              {/* <>
                <ListItemLink to="/users">
                  <i class="fa fa-users sideicon mr-2" aria-hidden="true"></i>{" "}
                  <span>Users</span>
                </ListItemLink>
              </> */}
            </div>
          </div>
        </li>

        {urlAllow("readTypes") ||
          urlAllow("readSkillType") ||
          urlAllow("readSkills") ? (
          <>
            <a
              class={`col_side ${tabclass("category") ? "" : "collapsed"}`}
              data-bs-toggle="collapse"
              href="#collapseExample1"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div className="sidees">
                <i class="material-icons mr-2" title="Categories">
                  category
                </i>
                <span>Categories</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons">keyboard_arrow_right</i>
                </span>
              </div>
            </a>
          </>
        ) : (
          <></>
        )}
        <div
          class={`collapse ${tabclass("category") ? "show" : ""}`}
          id="collapseExample1"
        >
          <div class="card card-body sides">
            {/* {urlAllow("readTypes") ? (
            <ListItemLink to="/industry">
              <i class="fa fa-industry sideicon mr-2" aria-hidden="true"></i>
              <span class="side_head">Industry Type</span>
            </ListItemLink>
          ) : null} */}

            {urlAllow("readSkillType") ? (
              <ListItemLink to="/masterskils" onClick={e => localStorage.removeItem("masterskillstatus")}>
                <div className="sidees">
                  <i class="material-icons mr-2" title="Skill Types">
                    widgets
                  </i>
                  <span class="side_head">Professions</span>
                </div>

              </ListItemLink>
            ) : null}
            {urlAllow("readSkills") ? (
              <ListItemLink to="/skills" onClick={e => { localStorage.removeItem("statusskillfilter"); localStorage.removeItem("skillstype") }}>
                <div className="sidees">
                  <i class="material-icons mr-2" title="Skills">
                    psychology
                  </i>
                  <span class="side_head" >Soft Skills</span>
                </div>

              </ListItemLink>
            ) : null}

            {urlAllow("readSkills") ? (
              <ListItemLink to="/categorylist" onClick={e => { localStorage.removeItem("categorytype"); localStorage.removeItem("categorystatus") }}>
                <div className="sidees">
                  <i class="material-icons mr-2" title="Blog/FAQ Category">
                    speaker_group
                  </i>
                  <span>Blog/FAQ Category</span>
                </div>


              </ListItemLink>
            ) : null}


          </div>
        </div>


        {/* <li className="nav-item">
        {urlAllow('types,categories,category') ? <>
          <a class={`col_side ${tabclass('catalogue') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
            <div className="sidees">
              <i class="material-icons mr-2" title="Catalogue">menu_book</i>
              <span >Catalogue</span>
            </div>   

            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>

          </a>
        </> : <></>}
      </li> */}

        {/* <div class={`collapse ${tabclass('catalogue') ? 'show' : ''}`} id="collapseExample2">
        <div class="card card-body sides">
          {urlAllow('types') ? <>
            <ListItemLink to="/types">
              <i className="material-icons sidenv mr-2" title="Types">category</i>
              <span>Types</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('categories') ? <>
            <ListItemLink to="/categories">
              <i className="material-icons sidenv mr-2" title="category">category</i>
              <span>Categories</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('category') ? <>
            <ListItemLink to={"/category/" + environment.resellerTypeId}>
              <i className="fa fa-list sidenv mr-2" aria-hidden="true" title="Reseller Category"></i>
              <span>Reseller Categories</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('category') ? <>
            <ListItemLink to={"/category/product/" + environment.productTypeId}>
              <i className="fa fa-list sidenv mr-2" aria-hidden="true" title="Product Category"></i>
              <span>Product Categories</span>
            </ListItemLink>
          </> : <></>}
        </div>
      </div> */}

        {/* <li className="nav-item">
        {urlAllow('features,plans') ? <>
          <a class={`col_side ${tabclass('plan') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample3">
            <div className="sidees">
              <i class="material-icons mr-2" >subscriptions</i>
              <span >Subscription Plan</span>
            </div>
            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>
          </a>
        </> : <></>}  
      </li> */}

        {/* 
      <div class={`collapse ${tabclass('plan') ? 'show' : ''}`} id="collapseExample3">
        <div class="card card-body sides">
          {urlAllow('features') ? <>
            <ListItemLink to="/features">
              <i class="material-icons sidenv" title="Plans Features">list_alt</i>
              <span>Plan Features</span>
            </ListItemLink>   
          </> : <></>}
          {urlAllow('plans') ? <>
            <ListItemLink to="/plans">
              <i class="material-icons sidenv" title="Plans">featured_play_list</i>
              <span>Plans</span>
            </ListItemLink>
          </> : <></>}
        </div>
      </div> */}
        <ListItemLink to="/transactions">
          <div className="sidees">
            <i class="material-icons mr-2" title="Transactions">
              paid
            </i>
            <span>Transactions</span>
          </div>


        </ListItemLink>
        <ListItemLink to="/contract">
          <div className="sidees">
            <i class="material-icons mr-2" title="Contract Management">
              rate_review
            </i>
            <span>Manual Additions</span>
          </div>


        </ListItemLink>
        <ListItemLink to="/emp/contr">
          <div className="sidees">
            <i class="material-icons mr-2" title="Contract Management">
            settings_suggest
            </i>
            
            <span>Automated Additions</span>
          </div>


        </ListItemLink>

        <ListItemLink to="/payment/va">
          <div className="sidees">
            <i class="material-icons mr-2" title="Virtual Payment">
              price_change
            </i>
            <span>VA Payments</span>
          </div>


        </ListItemLink>
        <ListItemLink to="/projects">
          <div className="sidees">
            <i class="material-icons mr-2" title="Job Posts">
              work_outline
            </i>
            <span>Job Posts</span>
          </div>


        </ListItemLink>


        <ListItemLink to="/testonimal">
          <div className="sidees">
            <i class="material-icons mr-2" title="Testimonials">
              message
            </i>
            <span>Testimonials</span>
          </div>


        </ListItemLink>


        {/* <ListItemLink to="/technologies">
        <i class="fa fa-desktop mr-2" aria-hidden="true"></i>
        <span>Technology</span>
      </ListItemLink> */}
        {urlAllow("readContent") ? (
          <>
            <ListItemLink to="/content">
              <div className="sidees">
                <i class="material-icons mr-2" title="Content Management">
                  content_paste_search
                </i>
                <span>Content Management</span>
              </div>
            </ListItemLink>
          </>
        ) : (
          <></>
        )}
        {urlAllow("readAssessment") ?
          <ListItemLink to="/assessment">
            <div className="sidees">
              <i class="material-icons mr-2" title="Assessment module ">
                assignment
              </i>
              <span>Assessment module </span>
            </div>


          </ListItemLink> : null}
        {/* {urlAllow("readQuestion")?   <ListItemLink to="/questions">
        <i class="fa fa-question-circle mr-2" aria-hidden="true"></i>
        <span>Questions</span>
      </ListItemLink>:null} */}

        {/* <ListItemLink to="/questions">
        <i class="fa fa-question-circle mr-2" aria-hidden="true"></i>
          <span>Questions</span>
        </ListItemLink> */}



        <ListItemLink to="/blogs" onClick={e => localStorage.removeItem("blogstatus")}>
          <div className="sidees">
            <i class="material-icons mr-2" title="Blogs">
              rss_feed
            </i>
            <span >Blogs</span>
          </div>


        </ListItemLink>

        <ListItemLink to="/faq" onClick={e => { localStorage.removeItem("statusfilter"); localStorage.removeItem("category") }}>

          <div className="sidees">
            <i class="material-icons mr-2" title="FAQs">
              quiz
            </i>
            <span>FAQs</span>
          </div>

        </ListItemLink>

        <ListItemLink to="/speciality" onClick={e => { localStorage.removeItem("category"); localStorage.removeItem("status") }}>

          <div className="sidees">
            <i class="material-icons mr-2" title="Speciality">
              local_police
            </i>
            <span>Speciality </span>
          </div>


        </ListItemLink>
        <ListItemLink to="/refferalls">
          <div className="sidees">
            <i class="material-icons mr-2" title="Referrals">
              transfer_within_a_station
            </i>
            <span>Referrals</span>
          </div>


        </ListItemLink>

        <ListItemLink to="/contact/list">
          <div className="sidees">
            <i class="material-icons mr-2" title="ContractUs">
              call_end
            </i>
            <span>Contact Us</span>
          </div>

        </ListItemLink>

        <ListItemLink to="/comissionsetting">
          <div className="sidees">
            <i class="material-icons mr-2" title="Settings">
              settings
            </i>
            <span>Settings</span>
          </div>

        </ListItemLink>

        {/*
      {urlAllow('currency') ? <>  
        <ListItemLink to="/currency">
          <i class="material-icons mr-2" title="Currency">currency_exchange</i>
          <span>Currency</span>
        </ListItemLink>
      </> : <></>} */}

        {/* <li className="nav-item">
        {urlAllow('bookingSystem') ? <>
          <a class={`col_side ${tabclass('api') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#apiIntegrations" role="button" aria-expanded="false" aria-controls="apiIntegrations">
            <div className="sidees">
              <i class="material-icons  mr-2" title="Catalogue">menu_book</i>
              <span >API Integrations</span>
            </div>

            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>

          </a>
        </> : <></>}
      </li> */}
        {/* 
      <div id="apiIntegrations" class={`collapse ${tabclass('api') ? 'show' : ''}`}>
        <div class="card card-body sides">
          {urlAllow('bookingSystem,reviews,accounting-system') ? <>
            <ListItemLink to="/bookingSystem">
              <i class="material-icons sidenv">book_online</i>
              <span>Booking System</span>
            </ListItemLink>
          </> : <></>}
          <ListItemLink to="/reviews" disabled>
            <i class="material-icons sidenv">book_online</i>
            <span>Reviews</span>
          </ListItemLink>
          <ListItemLink to="/accounting-system" disabled>
            <i class="material-icons sidenv">book_online</i>
            <span>Accounting System</span>
          </ListItemLink>
        </div>
      </div> */}

        {/* <li className="nav-item">
        {urlAllow('continents,countries,regions,cities') ? <>
          <a class={`col_side ${tabclass('geo') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#geoCollapse" role="button">
            <div className="sidees">
              <i class="material-icons mr-2" title="Catalogue">map</i>
              <span >Geo</span>
            </div>
            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>
          </a>
        </> : <></>}
      </li> */}

        {/* <div id="geoCollapse" class={`collapse ${tabclass('geo') ? 'show' : ''}`}>
        <div class="card card-body sides">
          {urlAllow('continents') ? <>
            <ListItemLink to="/continents">
              <i class="fa fa-globe sidenv " aria-hidden="true"></i>
              <span>Continents</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('countries') ? <>
            <ListItemLink to="/countries">
              <i class="fa fa-globe sidenv" aria-hidden="true"></i>
              <span>Countries</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('regions') ? <>
            <ListItemLink to="/regions">
              <i class="material-icons sidenv">reduce_capacity</i>
              <span>Regions</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('cities') ? <>
            <ListItemLink to="/cities">
              <i class="material-icons sidenv">location_city</i>
              <span>Cities</span>
            </ListItemLink>
          </> : <></>}
        </div>
      </div> */}

        {/* <ListItemLink to={`/dynamicpricelist`}>
        <i class="material-icons mr-2" title="Dynamic Pricing">subscriptions</i>
        <span>Dynamic Pricing</span>
      </ListItemLink> */}

        {/* <ListItemLink to={`/crm`}>
        <i class="material-icons mr-2" title="Dynamic Pricing">subscriptions</i>
        <span>Contract Templates</span>
      </ListItemLink> */}

        {/* <li className="nav-item">
        {urlAllow('features,plans') ? <>
          <a class={`col_side ${tabclass('dynamicPricing') ? '' : 'collapsed'}`} onClick={e => route('/dynamicpricelist')} data-bs-toggle="collapse" href="#collapseExampledynamicPricing" role="button" aria-expanded="false" aria-controls="collapseExampledynamicPricing">
            <div className="sidees">
              <i class="material-icons mr-2" title="Dynamic Pricing" >subscriptions</i>
              <span >Dynamic Pricing</span>
            </div>
            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>
          </a>
        </> : <></>}
      </li>

      <div class={`collapse ${tabclass('dynamicPricing') ? 'show' : ''}`} id="collapseExampledynamicPricing">
        <div class="card card-body sides">
          {urlAllow(`dynamicpricelist`) ? <>
            <ListItemLink to={`/dynamicpricelist`}>
              <i class="material-icons sidenv" title="Dynamic Pricing">subscriptions</i>
              <span>Dynamic Pricing</span>
            </ListItemLink>
          </> : <></>}
          {dynamicPriceModel.list.map(itm => {
            return <>
              {urlAllow(`dynamicprice/${itm.id}`) ? <>
                <ListItemLink to={`/dynamicprice/${itm.id}/add`} title={itm.name}>
                  <i class="material-icons sidenv" title={itm.name}>{itm.icon}</i>
                  <span className="text-truncate">{itm.name}</span>
                </ListItemLink>
              </> : <></>}
            </>
          })}
        </div>
      </div> */}

        {/* <li className="nav-item">
      <a class={`col_side ${tabclass('customer') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#collapseExamplecustomer" role="button" aria-expanded="false" aria-controls="collapseExamplecustomer">
            <div className="sidees">
              <i class="material-icons mr-2" title="Dynamic Pricing" >subscriptions</i>
              <span >Customers</span>
            </div>
            <div>
              <span className="side_icon">
                <i class="material-icons">keyboard_arrow_right</i>
              </span>
            </div>
          </a>
      </li> */}

        {/* <div class={`collapse ${tabclass('customer') ? 'show' : ''}`} id="collapseExamplecustomer">
        <div class="card card-body sides">
          <ListItemLink to={`/customer`}>
            <i class="material-icons sidenv" title="Dynamic Pricing">subscriptions</i>
            <span>Customer Data</span>
          </ListItemLink>

        </div>
      </div> */}
        {/* 
      <ListItemLink to="/customer">
        <i class="material-icons mr-2" title="Currency">currency_exchange</i>
      
        <span>Customers</span>
      </ListItemLink> */}

        {/* {urlAllow('emailtemplate') ? <>
        <ListItemLink to="/emailtemplate">
          <i class="material-icons mr-2" title="Currency">currency_exchange</i>
       
          <span>Email Template</span>
        </ListItemLink>
      </> : <></>} */}
        {/* <ListItemLink to="/reseller">
        <i class="material-icons mr-2" title="Dynamic Pricing">subscriptions</i>
        <span>Reseller</span>
      </ListItemLink> */}
      </ul>
    </div>
  );
};

export default Html;
