import environment from "../environment"
import moment, { } from "moment" 

const isTranslatePage = () => {
    let value = false
    let url = window.location.href
    if (url.includes('translation')) value = true
    return value
}

const generatekeysArr = (arr, key = 'typeofresult') => {
    let keys = {}
    if (!arr) return { keys, arr: [] }
    arr.map(itm => {
        if (keys[itm[key]]) {
            keys[itm[key]].push(itm)
        } else {
            keys[itm[key]] = [itm]
        }
    })
    return {
        keys, arr: Object.keys(keys).map(itm => {
            return { key: itm, value: keys[itm] }
        })
    }
}


const GetDayName = (dateInfo) => {
    const dateObject = new Date(dateInfo.year, dateInfo.month - 1, dateInfo.day); // Month is zero-based

    // Getting the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();

    // Converting the numeric day of the week to a string
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const dayName = daysOfWeek[dayOfWeek];

    return `${dayName}, ${moment(dateObject).format("MMM DD,YYYY")}`;

}
const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    if (file) {
        loader(true);
    }
    ApiClient.postFormData("upload/image?modelName=users", {
        file: file,
        modelName: "users",
    }).then((res) => {
        if (res.success) {
            let image = res.data.fullpath;
            ApiClient.put("edit/profile", {
                companyImage: image,
                id: user.id,
            }).then((res) => {
                if (res.success) {
                    toast.success("Image uploaded Successfully");
                    const dispatchdata = { ...user, companyImage: image };
                    dispatch(login_success(dispatchdata));
                }
            });
        } else {
        }
        loader(false);
    });
};

const userImg = (img) => {
    let value = '/assets/img/person.jpg'
    if (img != "") value = environment.api + 'images/users/' + img
    return value
}

const noImage = (img) => {
    let value = '/assets/img/noimag.jpg'
    if (img) value = environment.api + 'images/users/' + img
    return value
}

const noImg = (img, modal = 'blogs') => {
    let value = '/assets/img/placeholder.png'
    if (img) value = environment.api + 'img/' + img
    return value
}

const getPrams = (p) => {
    const params = new URLSearchParams(window.location.search)
    return params.get(p)
}


const isNumber = (e) => {
    let key = e.target;
    let maxlength = key.maxLength ? key.maxLength : 1;

    let max = Number(key.max ? key.max : key.value);
    if (Number(key.value) > max) key.value = max;

    // let min = key.min;
    // if (min && Number(key.value)<Number(min)) key.value = min;

    if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
    key.value = key.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');

    return key.value
}

const isRatio = (e) => {
    let key = e.target;
    let maxlength = key.maxLength ? key.maxLength : 1;

    let max = Number(key.max ? key.max : key.value);
    if (Number(key.value) > max) key.value = max;

    // let min = key.min;
    // if (min && Number(key.value)<Number(min)) key.value = min;

    if (key.value.length > maxlength) key.value = key.value.slice(0, maxlength);
    key.value = key.value.replace(/[^0-9.>]/g, '').replace(/(\..*?)\..*/g, '$1');

    return key.value
}

const find = (arr, value, key = 'key') => {
    let ext = arr?.find(itm => itm[key] == value)
    return ext
}


/*###################### Form Methods #########################*/

// get Single field error
const getError = (key, fvalue, formValidation) => {
    let ext = find(formValidation, key)
    let res = matchError(ext, fvalue)
    return res
}

const emailRequiredFor = (role) => {
    let value = false
    if (role == 'Clinic Admin' || role == 'Counsellor' || role == 'Owner' || role == 'admin') value = true
    return value
}


const validateUsername = (val) => {
    return /^(?=[a-zA-Z0-9._-]{8,20}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$/.test(val);
}

const dialMatch = (val) => {
    let value = false
    value = val.match(/^(?=.*[0-9])(?=.*[+])[0-9+]{2,5}$/)
    return value
}


const emailvalidation = (val) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val)) {
        return true
    }
    return false
}

// match errors for fields
const matchError = (ext, fValue) => {

    let invalid = false
    let kValue = fValue[ext.key]
    let value = { minLength: false, maxLength: false, confirmMatch: false, required: false }
    if (ext.required) {
        if (!kValue || (!kValue.length && typeof kValue != 'object')) {
            invalid = true
        }
    }
    let message = '';

    if (ext.minLength && kValue) {
        if (kValue.length < ext.minLength) value.minLength = true
    }
    if (ext.maxLength && kValue) {
        if (kValue.length > ext.maxLength) value.maxLength = true
    }
    if (ext.email && kValue) {
        if (!emailvalidation(kValue)) {
            value.email = true;
            message = ext?.message || `Email is invalid`;
        }
    }
    if (ext.dialCode && kValue) {
        if (dialMatch(kValue)) {
            kValue.indexOf("+");
            if (kValue.indexOf("+") != 0) {
                value.dialCode = true
            }

        } else {
            value.dialCode = true
        }
    }

    if (ext.username && kValue) {
        if (!validateUsername(kValue)) value.username = true
    }

    if (ext.confirmMatch && kValue) {
        if (fValue[ext.confirmMatch[0]] != fValue[ext.confirmMatch[1]]) value.confirmMatch = true
    }

    let vArr = Object.keys(value)
    vArr.map(itm => {
        if (value[itm]) invalid = true
    })

    let res = { invalid: invalid, err: value }
    return res
}

// get form error (All Fields)
const getFormError = (formValidation, fvalue) => {
    let invalid = false
    formValidation.map(ext => {
        if (matchError(ext, fvalue).invalid) {
            invalid = true
        }
    })

    return invalid
}

/* ###################### Form Methods end #########################  */

const route = (route) => {
    localStorage.setItem('route', route)
    let el = document.getElementById('routerDiv')
    setTimeout(() => {
        if (el) el.click()
    })
}



function findMinMaxDates(dateObjects, key) {
    if (!dateObjects || dateObjects.length === 0) {
        return [" ", " "];
    }

    // Extract dates from the specified key in each object
    const dates = dateObjects.map(obj => new Date(obj[key]));

    // Find the shortest (earliest) and highest (latest) dates
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    // Format the results if needed (e.g., toISOString() for standard format)
    // const minDateStr = minDate.toISOString().split('T')[0];
    // const maxDateStr = maxDate.toISOString().split('T')[0];

    return [maxDate, minDate];
}


function getDayNameByDate(dateString) {
    // Parse the date string to obtain a Date object
    const dateArray = dateString.split('-');
    const day = parseInt(dateArray[0], 10);
    const month = parseInt(dateArray[1], 10) - 1; // Months are zero-based in JavaScript
    const year = parseInt(dateArray[2], 10);

    const dateObject = new Date(year, month, day);

    // Define an array of day names
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the day index from the date object and return the corresponding day name
    const dayIndex = dateObject.getDay();
    const dayName = dayNames[dayIndex];

    return dayName;
}

const GetHourMinutesDates = (start_date, end_Date) => {
    var today = new Date(start_date);
    var Christmas = new Date(end_Date);
    var diffMs = (Christmas - today); // milliseconds between now & Christmas
    var diffDays = Math.floor(diffMs / 86400000); // days
    var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    if (diffHrs < 10) {
        diffHrs = "0" + diffHrs
    }
    if (diffMins < 10) {
        diffMins = "0" + diffMins
    }
    return diffHrs + ":" + diffMins + " hrs"
}

//  For Calculating the AMount of Platform Fee According to Virtual Assistant Hourly Rate
const CalculatePercentAmount = (dollar, platformfee) => {
    let result = 0;
    const platformAmount = parseFloat(platformfee);
    const clientAmount = parseFloat(dollar);
    if (clientAmount <= 10) {
        return clientAmount+2

    }

    if (clientAmount && clientAmount !== 0) {
        result = (platformAmount / 100 * clientAmount).toFixed(2);
    } else {
        result = 0.00;
    }

    return parseFloat(Number(result) + clientAmount);
};

const ConvertTime=(p='',type='time')=>{
if(type=='time'){
    let timeString = p.split('T')[1];

    return moment.utc(timeString, 'HH:mm:ss.SSSZ').format('h:mm A');
}else{
    return moment.utc(p, 'DD-MM-YYYYTHH:mm:ss.SSSZ').format('DD/MM/YYYY h:mm A');
}
}

const convertDate=(p='',format='MM/DD/YYYY')=>{
    return moment.utc(p, 'YYYY-MM-DDThh:mm:SSSZ').format(format)
}


// function formatToTimezone(dateString, timeZone) {
//     const date = new Date(dateString); // Create a Date object from the UTC string

//     // Use Intl.DateTimeFormat to format the date to the desired timezone
//     const options = {
//         timeZone: timeZone,
//         year: 'numeric',
//         month: '2-digit',
//         day: '2-digit',
//         hour: '2-digit',
//         minute: '2-digit',
//         second: '2-digit',
//     };

//     const formatter = new Intl.DateTimeFormat([], options);
//     return formatter.format(date);
// }


// function formatToTimezone(dateString, timeZone) {
//     // Create a DateTime object in UTC
//     const utcDate = DateTime.fromISO(dateString, { zone: 'utc' });

//     // Convert to the desired timezone
//     const localDate = utcDate.setZone(timeZone);

//     // Format the date
//     return localDate.toFormat('yyyy-MM-dd HH:mm:ss');
// }

const MomentDateConvertor=(timeString,format='YYYY-MM-DD')=>{
    if(timeString){
        let formattedDate = moment(timeString).format(format)
        if (formattedDate =='Invalid date'){
            return ''
        }else{
            return formattedDate
        }
    }
    else{
        return ''
    }
}
const methodModel = { MomentDateConvertor,convertDate,ConvertTime, GetHourMinutesDates, getDayNameByDate, findMinMaxDates, userImg, GetDayName, route, noImage, isNumber, isRatio, find, getError, getFormError, getPrams, emailRequiredFor, emailvalidation, noImg, isTranslatePage, generatekeysArr, CalculatePercentAmount }
export default methodModel