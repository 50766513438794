import React, { useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import { Link } from 'react-router-dom';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import environment from '../../environment';
import Switch from "react-switch";
import CommonDeleteModal from '../CommonDelete&ActiveModal/CommonDeleteModal';
import CommonActiveModal from '../CommonDelete&ActiveModal/CommonActiveModal';

const Html = ({
    tab,
    edit,
    form,
    setform,
    ChangeStatus,
    ChangeRole,
    ShowActiveModal,
    setShowActiveModal,
    ChangeCatType,
    statusChange,
    pageChange,
    loading,
    deleteItem,
    filters,
    loaging,
    ShowDeleteModal,
    setShowDeleteModal,
    data,
    isAllow,

    total = { total }
}) => {
    const [DeleteId, setDeleteId] = useState("");
    const Delete = () => {
        deleteItem(DeleteId)
    }
    const [StatusData, setStatusData] = useState({});
    const ConfirmFunction = () => {
        statusChange(StatusData);
    }

    return (
        <Layout>
            <div className='heightVH'>  <CommonActiveModal show={ShowActiveModal} setShow={setShowActiveModal} confirm={ConfirmFunction} status={StatusData.status} />
                <CommonDeleteModal show={ShowDeleteModal} setShow={setShowDeleteModal} confirm={Delete} />
                <div className="normalFlex">
                    <h3 className="hedding mb-2">
                        Category
                    </h3>

                    <div className="d-flex align-items-center gap-2  flex-wrap">

                      

                        <div className="dropdown addDropdown mt-0 fullWidth">
                            <button className="btn btn-primary dropdown-toggle removeBg fullWidth" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                            </button>
                            <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                                <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                                <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                                <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                            </div>
                        </div>
                      

                        <div className=" fullWidth">
                            {/* <label>
              Category type<span className="star">*</span>
              </label> */}
                            {/* <select
                            className="form-control ccttype"
                            required
                            value={form?.cat_type}
                            onChange={(e) =>
                                ChangeCatType(e.target.value)
                            }
                        // value={form.data?.cat_type}
                        >
                            <option value="">Filters</option>
                            <option className="text-capitalize" value="Blog" >
                                Blog
                            </option>
                            <option className="text-capitalize" value="Faq" >
                                Faq
                            </option>
                        </select> */}

                            <div className="dropdown addDropdown mt-0 fullWidth">
                                <button className="btn btn-primary dropdown-toggle removeBg fullWidth" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {filters.type ? filters.type == "Blog" ? "Blog" : filters.type : 'Filters'}
                                </button>
                                <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                                    <a className={filters.type == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeCatType("")}>All Filters</a>
                                    <a className={filters.type == 'Blog' ? 'dropdown-item active' : 'dropdown-item'} onClick={e => ChangeCatType("Blog")} >Blog</a>
                                    <a className={filters.type == 'Faq' ? 'dropdown-item active' : 'dropdown-item'} onClick={e => ChangeCatType("Faq")} >Faq</a>
                                </div>
                            </div>
                        </div>

                        <div className='fullWidth'>  {isAllow('addSkillType') ? <Link className="btn btn-primary fullWidth" to="/AddEditCategory">
                            <i class="fa fa-plus plusAdd" aria-hidden="true"></i> Add  Category
                        </Link> : <></>}</div>
                    </div>


                </div>


                {tab == 'grid' ? <>

                </> : <>

                    <div className="table-responsive">

                        <div className='table_section'>

                            <table class="table">
                                <thead className='table_head'>
                                    <tr className='heading_row'>
                                        <th scope="col" className='table_data'>Name</th>

                                        <th scope="col" className='table_data'>Category Type</th>
                                        {/* <th scope="col" className='table_data'>Images</th>                                                                                               */}
                                        <th scope="col" className='table_data'>Status</th>
                                        <th scope="col" className='table_data'>Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((itm, index) => (<tr className='data_row'>
                                        <td className='table_dats' onClick={e => edit(itm.id)}>{itm.name}</td>
                                        <td className='table_dats' onClick={e => edit(itm.id)}>{itm.cat_type}</td>
                                        {/* <td className='table_dats' onClick={e => edit(itm.id)}> <img src={methodModel.userImg(itm.images)} className="user_imgs" /></td>                                */}

                                        <td className='table_dats'>   <div className={` ${itm.status}`} >
                                            <span className='contract'>
                                                {itm.status == 'deactive' ? <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={false} /> : <Switch onChange={e => { setStatusData(itm); setShowActiveModal("block") }} checked={true} />}
                                            </span>
                                        </div></td>
                                        <td className='table_dats'>
                                            <div className="action_icons">
                                                {isAllow('editSkillType') ? <>
                                                    <a className="edit_icon iconsWidthAdjust" onClick={e => edit(itm.id)}>
                                                        <i class="material-icons edit" title="Edit">edit</i>
                                                    </a>
                                                </> : <></>}


                                                {isAllow('deleteSkillType') ? <span className='edit_icon' onClick={() => { setDeleteId(itm.id); setShowDeleteModal("block") }}>
                                                    <i class="material-icons delete" title='Delete'> delete</i>

                                                </span> : <></>}

                                            </div>

                                        </td>

                                        {/* end */}

                                    </tr>))}
                                </tbody>
                            </table>

                        </div>


                    </div>

                </>}


                {/* {!loaging && total == 0 ? <div className="py-3 text-center">Nodf Data</div> : <></>} */}
                {loading ? <h3 className="text-center mt-2">Loading...</h3> : data.length == 0 ? (
                    <div className="text-danger text-center">No Data</div>
                ) : null}
                {
                    !loaging && total > filters.count ? <div className='paginationWrapper'>
                        <span>Show {filters.count} from {total} Categories</span>
                        <Pagination
                            currentPage={filters.page}
                            totalSize={total}
                            sizePerPage={filters.count}
                            changeCurrentPage={pageChange}
                        />
                    </div> : <></>
                }

                {loaging ? <div className="text-center py-4">
                    <img src="/assets/img/loader.gif" className="pageLoader" />
                </div> : <></>}</div>
        </Layout>
    );
};

export default Html;
