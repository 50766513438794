import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiClient from "../../methods/api/apiClient";
import { ToastsStore } from "react-toasts";
import Layout from "../../components/global/layout";
import loader from "../../methods/loader";
import userTableModel from "../../models/table.model";
import { userType } from "../../models/type.model";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import environment from "../../environment";
import { toast } from "react-toastify";
import Pagination from "react-pagination-js";
import methodModel from "../../methods/methods";
import datepipeModel from "../../models/datepipemodel";

const Reqrooter = () => {
  const user = useSelector((state) => state.user);
  const { id, role } = useParams();
  const searchState = useSelector((state) => state.search);
  const [filters, setFilter] = useState({
    page: 1,
    count: 20,
    search: "",
    userId: id,
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loaging, setLoader] = useState(true);
  const history = useHistory();
  const Navigate = useHistory();

  useEffect(() => {
    if (user && user.loggedIn) {
      setFilter({ ...filters, search: searchState.data });
      getData({ search: searchState.data, page: 1 });
    }
  }, [searchState]);

  const getData = (p = {}) => {
    setLoader(true);
    let filter = { ...filters, ...p };
    let url = "referalList";
    ApiClient.get(url, filter).then((res) => {
      if (res.success) {
        const data = res.data;
        setData(data);
        setTotal(res.total);
      }
      setLoader(false);
    });
  };

  const pageChange = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };

  const view = (id) => {
    history.push("/userDetail/" + id);
  };

  const backButton=()=>{
    // Send the roles in params to open the perticular tab in the listing page
    if(role === "recruiter") Navigate.push(`/refferalls?role=recruiter&id=${environment?.RecruiterId}`)
    else if(role === "employer") Navigate.push(`/refferalls?role=employer&id=${environment?.EmployerId}`)
    else Navigate.push(`/refferalls?role=virtual&id=${environment?.VirtualId}`)
  }

  return (
    <>
      <Layout>
       <div className="heightVH"> 
       <div class="d-flex gap-1 align-items-center mb-3"><i  onClick={(e) => backButton()} class="fa fa-angle-double-left fontBack " title="Back" aria-hidden="true"></i><h3 class="hedding"> Invited {role === "recruiter" ? "Virtual Assistant & Employer": role === "employer" ? "Employer" : "Virtual Assistant"} </h3></div>
    

      <div className="table-responsive">
      <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Role</th>
              <th scope="col">Email</th>
              <th className="pad_th" scope="col">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr>
                <td>{item.fullName}</td>
                <td>{item?.roleDetail?.name}</td>
                <td>{item.email}</td>
                <td>
                  {" "}
                  <div className="d-flex gap-2">
                    <span className="inv">Invited</span>
                    <span className={`${item.status == "invited"? "reg" : "on_status "}`}>Registered</span>
                    <span className={`${item?.isProfileCreated ? "onb" : 'on_status'}`}>Onboard</span>
                  </div>
                </td>
              </tr>
            ))}
            {!loaging && total == 0 ? (
              <tr>
                <td colSpan={4}>
                  <div className="py-3 text-center">No Data</div>
                </td>
              </tr>
            ) : (
              <></>
            )}
          </tbody>
        </table>
      </div>

        {/* {data.map((item, index) => (<div className="d-flex bg_rotter align-items-center justify-content-between">
          <p className='mb-0'>{item.fullName}</p>
          <p>{item?.roleDetail?.name}</p>
          <span>{item.email}</span>
          <div className='d-flex'>
            <span className='badges_status'>Invited</span>
            <span className={`${item.status == "invited" ? "badges_status" : "badges_reg"}`}>Registered</span>
            <span className={`${item.isProfileCreated ? "badges_status" : "badges_reg"}`}>Onboard</span>
          </div>
        </div>))} */}

        {/* {!loaging && total == 0 ? <div className="py-3 text-center">No Data</div> : <></>} */}

        {!loaging && total > filters.count ? (
          <div className="paginationWrapper">
            <span>
              Show {filters.count} from {total} Users
            </span>
            <Pagination
              currentPage={filters.page}
              totalSize={total}
              sizePerPage={filters.count}
              changeCurrentPage={pageChange}
            />
          </div>
        ) : (
          <></>
        )}</div>
      </Layout>
    </>
  );
};

export default Reqrooter;
