import React, { useEffect, useState } from "react";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { DatePicker } from "antd";
import axios from "axios";
import environment from "../../environment";
import Layout from "../../components/global/layout";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import methodModel from "../../methods/methods";

const { RangePicker } = DatePicker;

export default function Details() {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };
  const [VirtualList, setVirtualList] = useState([]);
  let param = new URLSearchParams(window.location.search);
  const [loading, setloading] = useState(true);

  const id = param.get("contractId");
  const [filters, setfilters] = useState({
    name: "",
    start: "",
    end: "",
    contractId: id,
    paidHours: "",
    sortBy: "startTime desc" 
  });
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [Filter, setFilter] = useState({
    page: 1,
    count: 50,
    search: "",
    addedBy:
      user?.role?.id == environment.EmployerId ? user.id || "" : user.addedBy,
    contractId: "",
    isPaid: "",
  });
  const [VirtualId, setVirtualId] = useState("");
  const [ContractId, setContractId] = useState(false);
  const [VAList, setVAList] = useState([]);
  const [SelectedVA, setSelectedVA] = useState("");
  const [TransactionData, setTransactionData] = useState([]);
  const [Data, setData] = useState({
    VARATe: "----",
    RETAINER: "----",
    RETAINERLIMIT: "----",
    virtualName: "---",
  });
  const [totalunpiad, setTotalUnPiad] = useState([]);
  const [totalunpiadamount, settotalunpiadamount] = useState(0);
  const searchState = useSelector((state) => state.search);


  useEffect(() => {
    if (SelectedVA != "") {
      const SelectedData = VAList.find((item) => item.mainid == SelectedVA);
      setVirtualId(SelectedData?.id);
      setData((prev) => ({ ...prev, virtualName: SelectedData?.VAName }));
      GetTransactionData({ contractId: SelectedData?.contract_Id });
      setFilter({ ...Filter, contractId: SelectedData?.contract_Id });

      // GetRetainerData()
      ApiClient.get(`contract?id=${SelectedData?.contract_Id}`).then((res) => {
        if (res.success) {
          setContractId(res?.data?.billingStart);

          // if(res?.data?.billingStart == true)
          // {
          //   // location();
          // }
        }
      });
    } else {
      setFilter({ ...Filter, contractId: "" });

      GetTransactionData({ contractId: "" });
      setData({
        VARATe: "----",
        RETAINER: "----",
        RETAINERLIMIT: "----",
        virtualName: "---",
      });
    }
  }, [SelectedVA]);

  useEffect(() => {
    settotalunpiadamount(GetUnPaidTotalHours());
  }, [totalunpiad]);

  // const nav = () => {
  //   document.getElementById("Paymentmodalclose").click()
  //   setTimeout(() => {

  //     Navigate.push(`/payment?contractid=${id}`)
  //   }, 100)
  // }
  const [TotalDonepayment, setTotalDonepayment] = useState(0);
  const GetTransactionData = (p = {}) => {
    const newfilters = {
      ...p,
      contractId: id,
      payment_to: "adminTovirtual",
      isPaid: true,
      virtualId: methodModel.getPrams("virtual"),
    };
    loader(true);
    ApiClient.get(`transactions`, newfilters).then((res) => {
      if (res.success) {
        setTransactionData(res.data);
        let totalpay = 0;
        res.data.map((itm) => {
          totalpay += itm?.total_billingAmount;
        });
        setTotalDonepayment(totalpay);
      }
      loader(false);
    });
  };

  const [Count, setCount] = useState(0);
  const [paymentForm, setPaymentForm] = useState({
    document: "",
    payPrice: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [list, setlist] = useState([]);
  const [calculatedAmt, setCalulatedAmt] = useState(0);
  const referrer = document.referrer.toLowerCase();
  const [SelectedDateRange, setSelectedDateRange] = useState(null);

  const GetVALIST = () => {
    loader(true);
    const userid = "";
    ApiClient.get(`virtualListing?employerId=${userid}`).then(async (res) => {
      if (res.success) {
        const newdata = res.data;
        const updateddata = [];
        await newdata.map((item, index) => {
          //  We Need to make the per Virtual Assistant id unique so we need to do this
          updateddata.push({
            id: item?.virtualDetail?._id,
            fullName: item?.contractName,
            contract_Id: item.id,
            mainid: item?.virtualDetail?._id + `12${index}`,
            VAName: item?.virtualDetail?.fullName,
          });
        });
        setVAList(updateddata);
        setSelectedVA(updateddata[0]?.mainid || "");
      }
      loader(false);
    });
  };
  useEffect(() => {
    GetVALIST();
    // getAmount()
  }, []);

  const [billinghour, setbillinghour] = useState();
  const [billingminute, setbillingminute] = useState();
  const [totalprice, setTotalprice] = useState();
  const [endTime, setendDate] = useState();
  const [startTime, setstartDate] = useState();
  const [alignRecords, setAlignRecords] = useState([]);

  const getAmount = (p = {}) => {
    loader(true);
    const newFilter = {
      ...paymentDatefilter,
      ...p,
      contractId: id,
      paidHours: false,
    };
    ApiClient.get(`billing-amount`, newFilter).then((res) => {
      if (res.success) {
        setstartDate(res.payment_startTime);
        setendDate(res.payment_endTime);
        setbillinghour(res.total_billingHours);
        setTotalprice(res.total_billingAmount);
        setbillingminute(res?.total_billingMinutes);
        let totala = 0;
        res.data.map((item) => {
          totala += item.totalHourlyAmount;
        });

        setCalulatedAmt(totala);
        setPaymentForm({
          start: "",
          end: "",
          document: "",
          payPrice: totala,
        });
        loader(false);
        setlist(res?.listOfIds);
      }
      loader(false);
    });
  };

  const payNow = () => {
    let payload = {
      payPrice: paymentForm.payPrice,
      document: paymentForm.document,
      contractId: id,
      virtualId: methodModel.getPrams("virtual"),
      total_billingMinutes: billingminute,
      total_billingAmount: totalprice,
      total_billingHours: billinghour,
      payment_startTime: startTime,
      payment_endTime: endTime,
      listOfIds: [...list],
    };

    setSubmitted(true);

    if (!paymentForm.payPrice || !paymentForm.document || !list?.length) return;

    loader(true);
    ApiClient.post("card/admin-pay-virtual", payload).then((res) => {
      loader(false);
      if (res.success) {
        let el = document.getElementById("paymentModalOpen");
        if (el) el.click();

        toast.success("Payment Proccessed successfully");
        ApiClient.put("contract", {
          id: id,
          isContractPayment: true,
          isWeeklyPayment: true,
        }).then((res) => {
          if (res.success) {
            try {
              if (referrer.includes("zoho")) {
                Navigate.push("/perposallist");
              } else {
                // Navigate.goBack();
                document.getElementById("closemodal").click();
              }
            } catch {
              document.getElementById("closemodal").click();
              // Navigate.goBack("/contracts");
            }
          }
        });
      }
    });
  };

  const pay = () => {
    let el = document.getElementById("paymentModalOpen");
    if (el) el.click();
    setPaymentForm({
      document: "",
      payPrice: "",
    });
    // setDatepay({ start: "", end: "" });
    setPaymentdate("");
  };
  const AddBorderToRow = () => {
    let divs = document.querySelectorAll(".DynamicDateBorder");
    let currentDate = "";
    let lastDivInGroup = null;

    divs.forEach((div, index) => {
      let date = div.id.trim();

      if (date !== currentDate) {
        if (lastDivInGroup !== null) {
          lastDivInGroup.style.borderBottom = "1px solid #0000001a";
        }
        lastDivInGroup = null;
      }

      if (
        index === divs.length - 1 ||
        (index < divs.length - 1 && divs[index + 1].id.trim() !== date)
      ) {
        div.style.borderBottom = "1px solid #0000001a";
      }

      currentDate = date;
      lastDivInGroup = div;
    });
  };
  AddBorderToRow();

  const GetReports = (p = {}) => {
    const NewFilters = { ...filters, ...p };
    delete NewFilters.name;
    loader(true);
    ApiClient.get(`billing/list`, NewFilters).then((res) => {
      if (res.success) {
        setVirtualList(res?.data);
        setTotalUnPiad(res?.data);
        setAlignRecords(
          res?.data?.map((item, index) => {
            return {
              date: methodModel.convertDate(item?.startTime),
              hours: item?.totalDurationOfTime_hour || 0,
              minutes: item?.totalDurationOfTime_min || 0,
              check: checkLastDate(
                methodModel.convertDate(item?.startTime),
                res?.data?.[index + 1]?.startTime
                  ? methodModel.convertDate(res?.data?.[index + 1]?.startTime)
                  : ""
              ),
            };
          })
        );
        if (Count == 0 && res?.data[0]?.billings_details) {
          let data = res?.data.map((item) =>
            item?.billings_details?.map((itm) => itm)
          );

          const [maxDate, minDate] = methodModel.findMinMaxDates(
            compressedDat,
            "Date"
          );
          setSelectedDateRange([dayjs(minDate), dayjs(maxDate)]);

          setCount(Count + 1);
        }
      }
      loader(false);
      setloading(false);
    });
  };

  // Boxes

  //   const GetTotalHours = () => {
  //     let Result = 0;
  //     VirtualList.forEach((item) => {
  //         Result += Number(
  //             ((new Date(item?.endTime).getTime() - new Date(item.startTime).getTime()) / 3600000)
  //         ).toFixed(2);
  //     });
  //     return parseFloat(Result).toFixed(2);
  // }

  // getTime = ()=>
  // {
  //    ((new Date(item?.endTime).getTime() - new Date(item.startTime).getTime()) / 3600000)
  //               ).toFixed(2)
  // }

  // const GetTotalHours = () => {
  //   let Result = 0;
  //   VirtualList.forEach((item) => {
  //     const startTime = new Date(item.startTime).getTime();
  //     const endTime = new Date(item.endTime).getTime();

  //     if (!item.startTime || !item.endTime) {
  //       // startTime = 0;
  //       // Result += ((endTime - startTime) / 3600000)
  //     }
  //     else {
  //       Result += ((endTime - startTime) / 3600000);
  //     }

  //   });

  //   return Result.toFixed(2);
  // }
  const GetUnPaidTotalHours = () => {
    let Result = 0;

    totalunpiad.forEach((item) => {
      const startTime = new Date(item.startTime).getTime();
      const endTime = new Date(item.endTime).getTime();

      if (!item.startTime || !item.endTime || item?.isPaid) {
        // startTime = 0;
        // Result += ((endTime - startTime) / 3600000)
      } else {
        Result += (endTime - startTime) / 3600000;
      }
    });
    return Result.toFixed(2);
  };

  // const GetPaidTotalHours = () => {
  //   let Result = 0;

  //   VirtualList.forEach((item) => {
  //     const startTime = new Date(item.startTime).getTime();
  //     const endTime = new Date(item.endTime).getTime();

  //     if (!item.startTime || !item.endTime || !item?.isPaid) {
  //       // startTime = 0;
  //       // Result += ((endTime - startTime) / 3600000)
  //     }
  //     else {
  //       Result += ((endTime - startTime) / 3600000);
  //     }

  //   });

  //   return Result.toFixed(2);
  // }

  // const GetTotalHours = () => {
  //   let Result = 0;
  //   VirtualList.forEach((item) => {
  //       const startTime = isNaN(item.startTime) ? 0 : new Date(item.startTime).getTime();
  //       const endTime = isNaN(item.endTime) ? 0 : new Date(item.endTime).getTime();

  //       if (!isNaN(startTime) && !isNaN(endTime)) {
  //           Result += ((endTime - startTime) /  3600000);
  //       }
  //   });

  //   return Result.toFixed(2);
  // }

  //   const GetTotalHours=()=>{
  //     let Result=0;
  //     Result + =Number(
  //   (new Date(item?.endTime).getTime() -
  //     new Date(item.startTime).getTime()) /
  //   3600000
  // ).toFixed(2)
  //     return parseFloat(Result).toFixed(2)
  //   }

  const handleDateChange = (date, dateStrings) => {
    setSelectedDateRange(date);
    let dates = dateStrings;
    setfilters({
      ...filters,
      start: dates[0] || "",
      end: dates[1] || "",
      contractId: id || "",
    });
    GetReports({
      start: dates[0] || "",
      end: dates[1] || "",
      contractId: id || "",
    });
    GetHoursCalculaions({
      start: dates[0] || "",
      end: dates[1] || "",
      contractId: id || "",
    });
    GetHoursCalculaions(
      {
        start: dates[0] || "",
        end: dates[1] || "",
        contractId: id || "",
      },
      "true"
    );
    GetHoursCalculaions(
      {
        start: dates[0] || "",
        end: dates[1] || "",
        contractId: id || "",
      },
      "false"
    );
    // getAmount({ start: dates[0] || "", end: dates[1] || "" })
  };

  useEffect(() => {
    GetVirtualList();
  }, []);

  const GetVirtualList = () => {
    loader(true);
    ApiClient.get(
      `billing/list?addedBy=&contractId=${id}&start=&end=&sortBy=startTime desc`
    ).then((res) => {
      if (res.success) {
        setAlignRecords(
          res?.data?.map((item, index) => {
            return {
              date: methodModel.convertDate(item?.startTime),
              hours: item?.totalDurationOfTime_hour || 0,
              minutes: item?.totalDurationOfTime_min || 0,
              check: checkLastDate(
                methodModel.convertDate(item?.startTime),
                res?.data?.[index + 1]?.startTime
                  ? methodModel.convertDate(res?.data?.[index + 1]?.startTime)
                  : ""
              ),
            };
          })
        );
        setVirtualList(res?.data);
        setTotalUnPiad(res?.data);
      }
      loader(false);
      setloading(false);
    });
  };

  const checkLastDate = (startDate, endDate) => {
    if (endDate) {
      if (startDate === endDate) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const HandleTotalPaidUnpaidHoursbox = (Type) => {
    loader(true);
    setfilters({
      ...filters,
      paidHours: Type,
    });
    GetReports({
      paidHours: Type,
      contractId: id,
    });
    setTimeout(() => {
      loader(false);
    }, 700);
  };

  const DownloadVirtualReport = async (e) => {
    e.preventDefault();
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      // paidHr  ,totalHr, unPaidHr
      url: `${environment.api}export/billing?contractId=${id}&start=${filters.start}&end=${filters.end}&paidHr=${TotalPaidHours.hours}:${TotalPaidHours.minutes}&unPaidHr=${TotalUnPaidHours.hours}:${TotalUnPaidHours.minutes}&totalHr=${TotalHours.hours}:${TotalHours.minutes}&totalPaymentDone=${TotalDonepayment}&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });

    // Assume that blobData is the blob object that you want to convert to JSON

    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    link.download = `billing.xlsx`;
    link.click();
  };

  const [BillingDetails, setBillingDetails] = useState({});

  const [TotalHours, setTotalHours] = useState({ hours: "", minutes: "" });
  const [TotalPaidHours, setTotalPaidHours] = useState({
    hours: "",
    minutes: "",
  });
  const [TotalUnPaidHours, setTotalUnPaidHours] = useState({
    hours: "",
    minutes: "",
  });
  const GetHoursCalculaions = (p = {}, type = "") => {
    const NewFilters = { ...filters, ...p, contractId: id, paidHours: type };

    ApiClient.get(`billing/list`, NewFilters).then((res) => {
      if (res.success) {
        let hours = res?.total_billingHours;
        let minutes = res?.total_billingMinutes;
        let remainder = minutes / 60;
        let quotient = minutes % 60;
        hours += parseInt(remainder);
        minutes = quotient;
        if (type == "") {
          setTotalHours({ hours: hours || "00", minutes: minutes || "00" });
        } else if (type == "false") {
          setTotalUnPaidHours({
            hours: hours || "00",
            minutes: minutes || "00",
          });
        } else {
          setTotalPaidHours({ hours: hours || "00", minutes: minutes || "00" });
        }
      }
    });
  };
  useEffect(() => {
    GetHoursCalculaions({}, "");
    GetHoursCalculaions({}, "true");
    GetHoursCalculaions({}, "false");
  }, []);

  const uploadDoc = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    loader(true);
    ApiClient.postFormData("single/documents?modelName=transactions", {
      file: file,
    }).then((res) => {
      e.value = "";
      loader(false);
      if (res.success) {
        let imagePath = res.data.imagePath;
        setPaymentForm({ ...paymentForm, document: imagePath });
      }
    });
  };

  const [paymentdate, setPaymentdate] = useState(null);
  const [paymentDatefilter, setPaymentDateFilter] = useState({
    start: "",
    end: "",
  });
  const HandlePaymentDate = (date, dateStrings) => {
    setPaymentdate(date);
    let dates = dateStrings;
    setPaymentDateFilter({
      ...paymentDatefilter,
      start: dates[0] || "",
      end: dates[1] || "",
      contractId: id || "",
      paidHours: false,
    });
    getAmount({ start: dates[0] || "", end: dates[1] || "", paidHours: false });
  };

  // const [Datepay, setDatepay] = useState({ start: "", end: "" });

  // const HandleDatechange = (name, value) => {
  //   setDatepay({ ...Datepay, [name]: value });
  // };

  // useEffect(() => {
  //   if (Datepay.start && Datepay.end) {
  //     getAmount({ ...Datepay, paidHours: false, contractId: id });
  //   }
  // }, [Datepay.end]);

  const DownloadBillingPdf = async (e) => {
    e.preventDefault();
    loader(true);
    const token = await localStorage.getItem("token");
    const req = await axios({
      method: "get",
      url: `${
        environment.api
      }export/billing/pdf?contractId=${id}&projectId=${methodModel.getPrams(
        "project"
      )}&paidHr=${TotalPaidHours.hours}:${TotalPaidHours.minutes}&totalHr=${
        TotalHours.hours
      }:${TotalHours.minutes}&unPaidHr=${TotalUnPaidHours.hours}:${
        TotalUnPaidHours.minutes
      }&totalPaymentDone=${TotalDonepayment}&start=${filters.start}&end=${
        filters.end
      }&sortBy=${filters?.sortBy}&sorder=${filters?.sorder}&key=${filters?.key}`,
      responseType: "blob",
      body: { token: token },
    });
    var blob = new Blob([req.data], {
      type: req.headers["content-type"],
    });
    const blobUrl = URL.createObjectURL(blob);
    setTimeout(() => {
      fetch(blobUrl)
        .then((res) => res.json())
        .then(async (jsonData) => {
          const pdfUrl = jsonData.path;
          if (pdfUrl) {
            await fetch(`${environment.api}${pdfUrl}`)
              .then((res) => res.blob())
              .then((pdfBlob) => {
                const a = document.createElement("a");
                a.href = URL.createObjectURL(
                  new Blob([pdfBlob], { type: "application/pdf" })
                );
                a.download = "billing.pdf";
                a.click();
                loader(false);
              });
          } else {
            loader(false);
          }
        });
    }, 3000);
    loader(false);
  };

  const Getcalculculateminute = (res) => {
    let hours = parseInt(res?.total_billingHours);
    let minutes = parseInt(res?.total_billingMinutes);
    let remainder = minutes / 60;
    let quotient = minutes % 60;
    hours += parseInt(remainder);
    minutes = quotient;
    return `${hours || 0}  : ${minutes < 10 ? "0" + minutes : minutes || 0}`;
  };

  const getTotalHoursForDate = (targetDate) => {
    const totals = { hours: 0, minutes: 0 };

    alignRecords.forEach((record) => {
      const { date, hours, minutes } = record;

      // Check if the record's date matches the target date
      if (date === targetDate) {
        // Add hours and minutes
        totals.hours += hours;
        totals.minutes += minutes;

        // Convert overflow minutes to hours
        if (totals.minutes >= 60) {
          const additionalHours = Math.floor(totals.minutes / 60);
          totals.hours += additionalHours;
          totals.minutes = totals.minutes % 60;
        }
      }
    });

    if (totals.hours === 0 && totals.minutes === 0) {
      return `0 hrs 0 mins`;
    }

    return `${totals?.hours} hrs ${totals?.minutes} mins`;
  };

  const Sort=(key)=>{
    let sorder = 'asc';
    if (filters.key === key) {
      sorder = filters.sorder === 'asc' ? 'desc' : 'asc';
    }
    setFilter({ key, sorder });

    let sortBy = `${key} ${sorder}`;
    setfilters({ ...filters, sortBy, key, sorder });
    GetReports({ sortBy, key, sorder });
  };

  useEffect(() => {
    if (user && user.loggedIn) {
      setfilters({ ...filters, search: searchState.data });
      GetReports({ search: searchState.data });
    }
  }, [searchState]);

  return (
    <>
      <Layout>
        <div className="heightVH">
          {" "}
          <div className="cardit">
            <h3 className="report_heading">Details</h3>
            {/* <button
            onClick={(e) => Navigate.goBack()}
            className=" btn btn-primary mt-4 minbt"
            style={{ float: "right" }}
          >
            {" "}
            Back
          </button> */}
            <div className="row px-2">
              <div className="col-md-4">
                <label>Date range</label>
                <RangePicker
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  className="dateRangePicker"
                  placeholder={["Start Date", "End Date"]}
                  format="MM-DD-YYYY"
                  onChange={handleDateChange}
                  value={SelectedDateRange}
                />
              </div>
              <div className="col-md-8 text-end">
                <label className="d-block opacityView">buttons</label>
                <div className="d-flex gap-2 flex-wrap justify-content-md-end">
                  {" "}
                  {filters.start ||
                  filters.end ||
                  filters.paidHours === null ||
                  filters?.paidHours ||
                  filters?.paidHours === false ? (
                    <div className="">
                      <button
                        className="btn btn-primary minbt mb-0"
                        onClick={() => {
                          setfilters({
                            employerId: "",
                            virtualId: "",
                            start: "",
                            end: "",
                            contractId: id,
                            name: "",
                            paidHours: "",
                          });
                          setSelectedDateRange(null);
                          GetReports({
                            employerId: "",
                            end: "",
                            start: "",
                            contractId: id,
                            name: "",
                            virtualId: "",
                            paidHours: "",
                          });
                          GetHoursCalculaions({
                            employerId: "",
                            end: "",
                            start: "",
                            contractId: id,
                            name: "",
                            virtualId: "",
                            paidHours: "",
                          });
                          GetHoursCalculaions({
                            employerId: "",
                            end: "",
                            start: "",
                            contractId: id,
                            name: "",
                            virtualId: "",
                            paidHours: "",
                          },"true");
                          GetHoursCalculaions({
                            employerId: "",
                            end: "",
                            start: "",
                            contractId: id,
                            name: "",
                            virtualId: "",
                            paidHours: "",
                          },"false");
                        }}
                      >
                        Clear
                      </button>
                    </div>
                  ) : null}
                  <div>
                    {" "}
                    <button
                      className="btn btn-primary minbt mb-0"
                      onClick={(e) => DownloadBillingPdf(e)}
                    >
                      Export Pdf
                    </button>
                  </div>
                  <div>
                    {" "}
                    <button
                      className="btn btn-primary  minbt mb-0"
                      style={{ float: "right" }}
                      onClick={(e) => DownloadVirtualReport(e)}
                    >
                      Export
                    </button>
                  </div>
                  <div>
                    <button
                      onClick={(e) => Navigate.goBack()}
                      className=" btn btn-primary minbt mb-0"
                    >
                      {" "}
                      Back
                    </button>
                  </div>
                  {/* <div className="">
                  {list?.length?<>
                  <button
                    onClick={pay}
                    className="btn btn-primary mt-4  minbt  mb-2"
                  >
                    Pay Now
                  </button>
                  </>:<></>}
                </div> */}
                  {/* <div>
                  {VirtualList?.length ? <button onClick={e => Navigate.push("/virtualpayment")} className="btn btn-primary minbt mb-2 mt-4">Pay Now</button> : <></>}
                </div> */}
                </div>
              </div>
            </div>

            <div className="d-flex mt-4  gap-3 flex-wrap">
              <div className="pointer ">
                <p
                  onClick={(e) => HandleTotalPaidUnpaidHoursbox(null)}
                  className={`pms backitagin   text-center backitagindiv ${
                    filters?.paidHours === null ? "backitshadow" : ""
                  } `}
                >
                  {TotalHours.hours || 0}:
                  {TotalHours.minutes < 10
                    ? `0${TotalHours.minutes == "00" ? 0 : TotalHours.minutes}`
                    : TotalHours.minutes}
                  <br />
                  Total Hours
                </p>
              </div>
              <div className="pointer">
                <p
                  onClick={(e) => HandleTotalPaidUnpaidHoursbox(true)}
                  className={`pms backit  text-center backitagindiv pinkbg  ${
                    filters?.paidHours === true ? "backitshadow" : ""
                  }`}
                >
                  {TotalPaidHours.hours || 0}:
                  {TotalPaidHours.minutes < 10
                    ? `0${
                        TotalPaidHours.minutes == "00"
                          ? 0
                          : TotalPaidHours.minutes
                      }`
                    : TotalPaidHours.minutes}
                  <br />
                  Total Paid Hours
                </p>
              </div>
              <div className="pointer">
                <p
                  onClick={(e) => HandleTotalPaidUnpaidHoursbox(false)}
                  className={`pms backitagin  text-center backitagindiv graybgp  ${
                    filters?.paidHours === false ? "backitshadow" : ""
                  }`}
                >
                  {TotalUnPaidHours.hours || 0}:
                  {TotalUnPaidHours.minutes < 10
                    ? `0${
                        TotalUnPaidHours.minutes == "00"
                          ? 0
                          : TotalUnPaidHours.minutes
                      }`
                    : TotalUnPaidHours.minutes}
                  <br />
                  Total UnPaid Hours
                </p>
              </div>
              <div className="pointer">
                <p className="pms backit  text-center backitagindiv pinkbg ">
                  {TotalDonepayment
                    ? parseFloat(TotalDonepayment).toFixed(2)
                    : 0}{" "}
                  USD
                  <br />
                  Total Done Payment
                </p>
              </div>
            </div>
            {filters.paidHours ? (
              <>
                <div className="mt-3 px-2">
                  <div className="cmc_table ">
                    <div className="row cms firstcms ">
                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Contract Name</label>
                      </div>

                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Virtual Name</label>
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Virtual Rate</label>
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Period</label>
                      </div>

                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Amount</label>
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Total Hours</label>
                      </div>
                      <div className="col d-flex justify-content-center align-items-center">
                        <label>Status</label>
                      </div>
                    </div>

                    {TransactionData.map((item, index) => (
                      <div className="row cms  ">
                        <div className="col ">
                          <p className="pms npms text-info">
                            {item?.contract_details?.createdBy === "admin"
                              ? item?.contractName || item?.title || ""
                              : item?.contractDetail?.title || ""}
                          </p>
                        </div>
                        <div className="col ">
                          <p className="pms npms text-info">
                            {item?.virtual_details?.fullName}
                          </p>
                        </div>

                        <div className="col ">
                          <p className="pms npms text-info">
                            {item?.contract_details?.hourlyRate < 10
                              ? "0" + item?.contract_details?.hourlyRate
                              : item?.contract_details?.hourlyRate}{" "}
                            USD
                            {/* {item?.contract_details?.hourlyRate} USD */}
                          </p>
                        </div>

                        <div className="col ">
                          <p className="pms npms text-info">
                            {item?.payment_startTime ? (
                              <>
                                {moment(item?.payment_startTime).format(
                                  "DD MMM"
                                )}{" "}
                                -{" "}
                                {moment(item?.payment_endTime).format(
                                  "DD, YYYY"
                                )}
                              </>
                            ) : (
                              "---"
                            )}
                          </p>
                        </div>

                        <div className="col ">
                          <p className="pms npms text-info">
                            {item?.total_billingAmount
                              ? parseFloat(item?.total_billingAmount).toFixed(2)
                              : "00"}{" "}
                            USD
                          </p>
                        </div>

                        <div className="col ">
                          <p className="pms npms text-info">
                            {Getcalculculateminute(item)} hrs
                          </p>
                        </div>

                        <div className="col ">
                          <p className="pms npms text-info">Paid </p>
                        </div>
                      </div>
                    ))}
                    {/* {TransactionData.length === 0 ? (
                    <h1 className="text-center nodatath">No Data</h1>
                  ) : (
                    " "
                  )} */}
                    {/* {loading ? <h3 className="text-center mt-2">Loading...</h3> : TransactionData.length == 0 ? (
              <div className="text-danger text-center">No Data</div>
            ) : null} */}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="mt-3 px-2">
                  <div className="cmc_table cmTable table-new">
                    <div className="row cms firstcms cmcRowAdjust">
                      <div className="col d-flex align-items-center">
                        <label>Contract Name</label>
                      </div>

                      <div className="col d-flex align-items-center">
                        <label>Virtual Name</label>
                      </div>
                        <div className="col d-flex  align-items-center" >
                        <label>Virtual Rate</label>
                      </div>
                        <div className="col d-flex  align-items-center cursor-pointer" style={{ cursor: 'pointer' }} onClick={e => Sort('startTime')}>
                        <label style={{cursor:'pointer'}}>Start Date</label>
                      </div>
                      <div className="col d-flex align-items-center">
                        <label>End Date</label>
                      </div>

                      <div className="col d-flex  align-items-center">
                        <label>Start Time</label>
                      </div>
                      <div className="col d-flex  align-items-center">
                        <label>End Time</label>
                      </div>
                      <div className="col d-flex  align-items-center">
                        <label>Total Hours</label>
                      </div>
                      <div className="col d-flex  align-items-center">
                        <label>Daily hours</label>
                      </div>
                      <div className="col d-flex  align-items-center">
                        <label>Billing Status</label>
                      </div>
                    </div>

                    {VirtualList?.map((item, index) => (
                      <div
                        className="row cms DynamicDateBorder cmcRowAdjust"
                        id={new Date(
                          item.startTime ? item.startTime : 0
                        ).toLocaleDateString()}
                        onClick={(e) => {
                          setBillingDetails(item);
                        }}
                      >
                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {item?.contractDetail?.title ||
                              item?.contractDetail?.contractName ||
                              item?.projectDetail?.title ||
                              ""}
                          </p>
                        </div>
                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          {/* <Tooltip title={item?.addedByDetail?.fir}> */}
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {item?.virtualDetail?.fullName}
                          </p>
                          {/* </Tooltip> */}
                        </div>

                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          {/* <Tooltip title={item?.addedByDetail?.fir}> */}
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {item?.contractDetail?.hourlyRate < 10
                              ? "0" + item?.contractDetail?.hourlyRate
                              : item?.contractDetail?.hourlyRate}{" "}
                            USD
                          </p>
                          {/* </Tooltip> */}
                        </div>

                        <div
                          className="col "
                          data-toggle="modal"
                          id="DynamicDateBorder"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {methodModel.convertDate(item?.startTime || 0)}{" "}
                          </p>
                        </div>
                        <div
                          className="col "
                          data-toggle="modal"
                          id="DynamicDateBorder"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {methodModel.convertDate(item?.endTime || 0)}{" "}
                          </p>
                        </div>

                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            {methodModel.ConvertTime(item.startTime)}{" "}
                          </p>
                        </div>

                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {item.endTime
                              ? methodModel.ConvertTime(item.endTime)
                              : "---"}{" "}
                          </p>
                        </div>

                        {/* <div className="col ">
                <p className="pms npms text-info" style={{ cursor: "pointer" }}>
                  {new Date(item.startTime?item.startTime:0).toLocaleDateString()}{" "}
                </p>
              </div> */}

                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {/* {(
                        (new Date(item?.endTime ? item?.endTime : 0).getTime() -
                          new Date(item.startTime ? item.startTime : 0).getTime()) /
                        3600000
                      ).toFixed(2) > 0 ? (
                        (new Date(item?.endTime ? item?.endTime : 0).getTime() -
                          new Date(item.startTime ? item.startTime : 0).getTime()) /
                        3600000
                      ).toFixed(2) : 0}

                      {" "}
                      Minutes{" "} */}
                            {item?.endTime ? (
                              <>
                                {" "}
                                {item?.totalDurationOfTime_hour || "0"}:
                                {item?.totalDurationOfTime_min < 10
                                  ? `0${
                                      item?.totalDurationOfTime_min == "00"
                                        ? 0
                                        : item?.totalDurationOfTime_min
                                    }`
                                  : item?.totalDurationOfTime_min || "00"}
                              </>
                            ) : (
                              "---"
                            )}
                          </p>
                        </div>
                        <div
                          className="col"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {alignRecords?.[index]?.check === true ? (
                              <>
                                {getTotalHoursForDate(
                                  methodModel.convertDate(item?.startTime)
                                )}
                              </>
                            ) : null}
                          </p>
                        </div>
                        <div
                          className="col "
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <p
                            className="pms npms text-info"
                            style={{ cursor: "pointer" }}
                          >
                            {item?.billingStatus === "notSubmitted"
                              ? "Not Submitted"
                              : "Submitted" || "---"}
                          </p>
                        </div>

                        <div>
                          {/* <div className='col'>
                    <button onClick={nav} className='btn btn-primary float-end mb-2'>Payment</button>
                  </div> */}

                          {/* <div className='col-2'>
                                        <p className='pms gms'>{parseFloat(item?.TotalVirtualHourlyRate || 0).toFixed(2)} USD</p>
                                    </div> */}
                          {/* {item?.billings_details && item?.billings_details[0] && item?.billings_details?.map((itm) => (
                                        <>
                                            <div className='col-2 '>
                                                <p className='pms '> {moment(itm?.startTime).format('MMM,DD YYYY hh:mm a')} </p>
                                            </div>

                                            <div className='col-2 ' >
                                                <p className='pms '> {moment(itm?.endTime).format('MMM,DD YYYY hh:mm a')} </p>
                                            </div>
                                            <div className='col-2 ' >
                                            </div>
                                            <div className='col-2 ' >
                                                <p className='pms '>{parseFloat(itm?.totalTime).toFixed(2)} hrs</p>
                                            </div>
                                            <div className='col-2 ' >

                                            </div>

                                        </>
                                    ))} */}
                        </div>
                      </div>
                    ))}
                 
                    {loading ? (
                      <h3 className="text-center mt-2">Loading...</h3>
                    ) : VirtualList.length == 0 ? (
                      <div className="text-danger text-center">No Data</div>
                    ) : null}
                  </div>
                </div>

               
              </>
            )}
          </div>
          {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal">
          Launch modal
        </button> */}
          <div
            class="modal fade ss"
            id="myModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-xl" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Billing Details
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="start-time">Start Time:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="start-time"
                          value={new Date(
                            BillingDetails.startTime
                          ).toLocaleTimeString()}
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="end-time">End Time:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="end-time"
                          value={
                            BillingDetails.endTime
                              ? new Date(
                                  BillingDetails.endTime
                                ).toLocaleTimeString()
                              : "--"
                          }
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="name">Name:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="name"
                          value={BillingDetails?.addedByDetail?.fullName}
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="country">Country:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="country"
                          value={BillingDetails?.country}
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="city">City:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="city"
                          value={BillingDetails.city}
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="state">State:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="state"
                          value={BillingDetails.state}
                          readonly
                        />
                      </div>
                      <div class="form-group">
                        <label for="state">Pin Code:</label>
                        <input
                          type="text"
                          disabled
                          class="form-control"
                          id="state"
                          value={BillingDetails.pincode}
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div id="map">
                        <iframe
                          width="100%"
                          height="700"
                          frameborder="0"
                          referrerpolicy="no-referrer-when-downgrade"
                          src={`https://www.google.com/maps/embed/v1/place?key=${
                            environment.googleMapAPiKey
                          }&q=${BillingDetails?.lat || 0},${
                            BillingDetails?.lng || 0
                          }`}
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Payment</h5>
              </div>

              <div class="modal-body">
                <label>Total UnPaid Hours</label>
                <input
                  className="form-control"
                  placeholder="UDS"
                  value={totalunpiadamount}
                  disabled
                  readOnly
                  onChange={(e) => settotalunpiadamount(e.target.value)}
                />

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" id="Paymentmodalclose" data-bs-dismiss="modal">Back</button>
                <button type="button" onClick={nav} class="btn btn-primary">Pay Now</button>
              </div>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </Layout>

      <button
        type="button"
        class="btn btn-primary d-none"
        id="paymentModalOpen"
        data-bs-toggle="modal"
        data-bs-target="#paymentModal"
      ></button>

      <div
        class="modal fade"
        id="paymentModal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                Payment
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                payNow();
              }}
            >
              <div class="modal-body responsive">
                <div className="mb-3">
                  <label>Date Range</label>
                  <RangePicker
                    key={paymentdate}
                    className="dateRangePicker responsive"
                    placeholder={["Start Date", "End Date"]}
                    format="MM-DD-YYYY"
                    onChange={HandlePaymentDate}
                    value={paymentdate}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />

                  {/* <div className="d-flex">
                    <input
                      className="form-control"
                      onChange={(e) =>
                        HandleDatechange("start", e.target.value)
                      }
                      type="date"
                      value={moment(Datepay.start).format("YYYY-MM-DD")}
                    />
                    <input
                      className="form-control mx-1"
                      min={Datepay?.start}
                      onChange={(e) => HandleDatechange("end", e.target.value)}
                      type="date"
                      value={moment(Datepay.end).format("YYYY-MM-DD")}
                    />
                    {Datepay.start && Datepay.end ? (
                      <button
                        onClick={(e) => {
                          loader(true)
                          setDatepay({ start: "", end: "", payPrice: "" });
                          setPaymentForm({ ...paymentForm, payPrice: "" });
                          setTimeout(() => {
                            loader(false)
                          }, 500);
                        }}
                        className="btn btn-primary"
                      >
                        Clear
                      </button>
                    ) : (
                      ""
                    )}
                  </div> */}
                </div>
                <div className="mb-3">
                  <label>Amount</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    value={
                      paymentForm.payPrice
                        ? `${parseFloat(paymentForm.payPrice).toFixed(2)} USD`
                        : "0 USD"
                    }
                    maxLength={12}
                    onChange={(e) =>
                      setPaymentForm({
                        ...paymentForm,
                        payPrice: methodModel.isNumber(e),
                      })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label>Document</label>
                  <div>
                    {paymentForm.document ? (
                      <>
                        <div className="">
                          <a
                            href={`${environment.api}images/transactions/${paymentForm.document}`}
                            target="_new"
                          >
                            <i className="fa fa-file"></i>{" "}
                            {paymentForm.document}
                          </a>{" "}
                          <i
                            className="fa fa-times pointer"
                            onClick={(e) =>
                              setPaymentForm({ ...paymentForm, document: "" })
                            }
                          ></i>
                        </div>
                      </>
                    ) : (
                      <>
                        <label className="btn btn-primary">
                          <input
                            type="file"
                            className="d-none"
                            onChange={(e) => uploadDoc(e)}
                          />
                          Upload Doc
                        </label>
                      </>
                    )}
                  </div>

                  {submitted && !paymentForm.document ? (
                    <>
                      <div className="text-danger text-small">
                        Document is Required
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  id="closemodal"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
